<template>
    <div class="container-fluid mt-4">
    <div class="row">
        <div class="col">
            <!-- <b-card> -->
            <!-- <b-card-title>Filter Options</b-card-title> -->
            <b-button-group size="sm">
            <b-button
            v-for="option in filterOptions"
            :key="option"
            @click="applyFilter(option)"
            :variant="selectedFilter === option ? 'primary' : 'secondary'"
            >
            {{ option }}
            </b-button>
        </b-button-group>
            <!-- </b-card> -->
        </div>
        <div class="col">
            <!-- <b-card> -->
                <div class="d-flex">
                    <b-form-datepicker size="sm" v-model="selectedDateRange.start" class="mr-2" placeholder="Start Date" />
                    <b-form-datepicker size="sm" v-model="selectedDateRange.end"  class="mr-2" placeholder="End Date" />
                    <b-button size="sm" @click="applyDateFilter">Apply</b-button>
                </div>
            <!-- </b-card> -->
        </div>
    </div>
    <h3 class="mt-2 card-header-metrics" >Dashboard</h3>
    <hr/>
    <div class="row">
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">Unassigned Conversations</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getUnassignedConversations')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{ reports.unassignedConversations.length }}</h3>
                <!-- <div class="card-footer text-right">
                    <span><i class="fas fa-eye"></i> View</span>
                    <a href="#" class="btn btn-link"><i class="fas fa-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">Unresolved Conversations</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getUnresolvedConversations')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{ reports.unresolvedConversations.length }}</h3>
                <!-- <div class="card-footer text-right">
                    <span><i class="fas fa-eye"></i> View</span>
                    <a href="#" class="btn btn-link"><i class="fas fa-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">Avg. Response Time (minutes)</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getAverageResponseTime')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{reports.averageResponseTime}}m</h3>
                <!-- <div class="card-footer text-right">
                    <span><i class="fas fa-eye"></i> View</span>
                    <a href="#" class="btn btn-link"><i class="fas fa-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">Avg. Resolved Time (minutes)</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getAverageResolveTime')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{reports.averageResolveTime}}m</h3>
                <!-- <div class="card-footer text-right">
                    <span><i class="fas fa-eye"></i> View</span>
                    <a href="#" class="btn btn-link"><i class="fas fa-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">New Contacts</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getNewContacts')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{reports.newContacts.length}}</h3>
            </div>
        </div>
        <div class="col-4">
            <div class="card pb-5">
                <div class="card-body d-flex justify-content-center">
                    <h5 class="card-title">Active Coordinators</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile('getActiveCoordinators')" ></i></button> -->
                </div>
                <h3 class="card-text text-center">{{reports.activeCoordinators.length}}</h3>
                <!-- <div class="card-footer text-right">
                    <span><i class="fas fa-eye"></i> View</span>
                    <a href="#" class="btn btn-link"><i class="fas fa-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
        
        <div>
    </div>
    </div>
    <hr/>
    <h3 class="mt-2 card-header-metrics" >Other Details</h3>
    <hr/>
    <div class="row">
        
    </div>
    <div class="row">
        <div class="col-4">
            <div class="card  text-dark pb-5">
                <div class="card-body">
                    <h5 class="card-title">Resolved Vs. Pending</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile()" ></i></button> -->
                </div>
                <apexchart type="donut" :options="resolvedVsPending_Options" :series="resolvedVsPending_series"></apexchart>
            </div>
        </div>
        <div class="col-4">
            <div class="card  text-dark pb-5">
                <div class="card-body">
                    <h5 class="card-title">User Roles</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile()" ></i></button> -->
                </div>
                <apexchart :key="chartKey" type="bar" :options="rolesCount_Options" :series="rolesCount_series"></apexchart>
            </div>
        </div>
        <div class="col-4">
            <div class="card  text-dark pb-5">
                <div class="card-body">
                    <h5 class="card-title">Conversation per Message Type</h5>
                    <!-- <button><i class="fas fa-file-excel excel-icon" @click="downloadExcelFile()" ></i></button> -->
                </div>
                <div id ='chart'>
                    <apexchart ref="chart" type="donut" :options="messageType_Options" :series="messageType_series"></apexchart>
                </div>            
            </div>
        </div>
    </div>
    <div class="row">
        
    </div>

</div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
const ExcelJS = require('exceljs');
import ChatWindowComponent from './ChatWindowComponent.vue';
    export default {
        props:['user'],
        data(){
            return {
                allMessage:[],
                newMessage:'',
                users:[],
                typingTimer:'false',
                typingUser:'',
                users:[],
                recipient_id:null,
                selectedUsers:[],
                windowFeatures: 'width=500,height=300,top=100,left=100,scrollbars=yes,location=yes,resizable=yes',
                chatSessionId:null,
                recipient:null,
                filterOptions: ['Current Hour', '1 Week', '4 Weeks', '1 Year', 'Mtd', 'Qtd', 'Ytd'],
                selectedFilter: '',
                selectedDateRange: { start: null, end: null },
                reports:{
                    unassignedConversations:0,
                    unresolvedConversations:0,
                    averageResponseTime:0,
                    averageResolveTime:0,
                    activeCoordinators:0,
                    newContacts:0,
                },
                options: {},
                resolvedVsPending_series: [],
                resolvedVsPending_Options: {
                    labels: ['Resolved', 'Pending'],
                    chart: {
                    type: 'donut',
                    },
                    legend: {
                    position: 'bottom'
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 100
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                    }]
                },
                rolesCount_series: [{
                    name: 'Users',
                    data: [],
                }],
                rolesCount_Options: {
                    chart: {
                    type: 'bar',
                    height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                    enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                    title: {
                        text: 'Number of Users'
                    }
                    },
                    fill: {
                    opacity: 1
                    },
                    tooltip: {
                    y: {
                        formatter: function (val) {
                        return val
                        }
                    }
                    }
                },
                chartKey:0,
                messageType_series: [],
                messageType_Options: {
                    labels: [],
                    chart: {
                    type: 'donut',
                    },
                    legend: {
                    position: 'bottom'
                    },
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 100
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                    }]
                },
            }
        },
        components:{
            ChatWindowComponent,
        },
        mounted(){
            this.applyFilter('Current Hour');
            // this.getUnassignedConversations()
        },
        methods:{
            applyFilter(option) {
                this.selectedDateRange = { start: null, end: null }
                this.selectedFilter = option;
                // Make an API call with Axios using this.selectedFilter
                this.getUnassignedConversations();
                this.getUnresolvedConversations();
                this.getAverageResponseTime();
                this.getAverageResolveTime();
                this.getActiveCoordinators();
                this.getNewContacts();
                this.graphResolvedVsPending();
                this.graphRolesCount();
                this.getConversationPerMessageType();
                // this.fetchData();
            },
            applyDateFilter() {
                this.selectedFilter = '',
            // Make an API call with Axios using this.selectedDateRange
                this.fetchData();
                this.getUnassignedConversations();
                this.getUnresolvedConversations();
                this.getAverageResponseTime();
                this.getAverageResolveTime();
                this.getActiveCoordinators()
                this.getNewContacts();
                this.graphResolvedVsPending();
                this.graphRolesCount();
                this.getConversationPerMessageType();
            },
            fetchData() {
            // Use Axios to make an API call to Laravel backend
                axios.get('/getKpiConversations', {
                    params: {
                    filter: this.selectedFilter,
                    dateRange: this.selectedDateRange,
                    },
                })
                .then(response => {
                    // Handle the API response
                    console.log(response.data);
                })
                .catch(error => {
                    // Handle errors
                    console.error(error);
                });
            },
            async getUnassignedConversations(is_export = false){
                if(is_export){
                    // const workbook = new ExcelJS.Workbook();
                    // const worksheet = workbook.addWorksheet('Data');

                    // worksheet.columns = [
                    //     { key: 'id', header: 'ID', format: 'General' },
                    //     { key: 'email', header: 'Email', format: 'General' },
                    //     { key: 'name', header: 'Name', format: 'General' }
                    // ];

                    // response.data.forEach(row => {
                    //     worksheet.addRow(row);
                    // });

                    // const buffer = await workbook.xlsx.writeBuffer();
                    // const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // const downloadLink = document.createElement('a');
                    // downloadLink.href = window.URL.createObjectURL(blob);
                    // downloadLink.download = 'unassigned_conversations.xlsx'; // File name
                    // downloadLink.click();
                }
                else{
                    axios.get('/getUnassignedConversations', {
                        params: {
                            filter: this.selectedFilter,
                            dateRange: this.selectedDateRange,
                        },
                    })
                    .then(response => {
                        this.reports.unassignedConversations = response.data
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            async getUnresolvedConversations(is_export = false){
                if(is_export){
                    
                }
                else{
                    axios.get('/getUnresolvedConversations', {
                        params: {
                            filter: this.selectedFilter,
                            dateRange: this.selectedDateRange,
                        },
                    })
                    .then(response => {
                        this.reports.unresolvedConversations = response.data
                    })
                    .catch(error => {
                        console.error(error);
                    }); 
                }
                
            },
            async getAverageResponseTime(is_export = false){
                if(is_export){

                }
                else{
                    axios.get('/getAverageResponseTime', {
                        params: {
                            filter: this.selectedFilter,
                            dateRange: this.selectedDateRange,
                        },
                    })
                    .then(response => {
                        if(!!response.data[0].avg_time){
                            this.reports.averageResponseTime = Math.round(response.data[0].avg_time)
                        }
                        else{
                            this.reports.averageResponseTime = 0
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
                
            },
            async getAverageResolveTime(is_export = false){
                if(is_export){

                }
                else{
                    axios.get('/getAverageResolveTime', {
                        params: {
                            filter: this.selectedFilter,
                            dateRange: this.selectedDateRange,
                        },
                    })
                    .then(response => {
                        if(!!response.data[0].avg_time){
                            this.reports.averageResolveTime = Math.round(response.data[0].avg_time)
                        }
                        else{
                            this.reports.averageResolveTime = 0
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
                
            },
            async getActiveCoordinators(is_export = false){
                if(is_export){
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Data');

                    worksheet.columns = [
                        { key: 'id', header: 'ID', format: 'General' },
                        { key: 'email', header: 'Email', format: 'General' },
                        { key: 'name', header: 'Name', format: 'General' }
                    ];

                    this.reports.activeCoordinators.forEach(row => {
                        worksheet.addRow(row);
                    });

                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = 'coordinators.xlsx';
                    downloadLink.click();
                }
                else{
                    axios.get('/getActiveCoordinators', {
                        params: {
                            filter: this.selectedFilter,
                            dateRange: this.selectedDateRange,
                        },
                    })
                    .then(response => {
                        if(!!response.data){
                            this.reports.activeCoordinators = response.data
                        }
                        else{
                            this.reports.activeCoordinators = 0
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
                
            },
            async getNewContacts(is_export = false){
                if(is_export){
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Data');

                    worksheet.columns = [
                        { key: 'id', header: 'ID', format: 'General' },
                        { key: 'email', header: 'Email', format: 'General' },
                        { key: 'name', header: 'Name', format: 'General' }
                    ];

                    this.reports.newContacts.forEach(row => {
                        worksheet.addRow(row);
                    });

                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = 'new_contacts.xlsx';
                    downloadLink.click();
                }
                else{
                    axios.get('/getNewContacts', {
                    params: {
                        filter: this.selectedFilter,
                        dateRange: this.selectedDateRange,
                    },
                })
                .then(async response => {
                        this.reports.newContacts = response.data
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
                
            },
            downloadExcelFile(functionName){
                if (typeof this[functionName] === 'function') {
                // Call the function dynamically
                this[functionName](true);
                } else {
                    Swal.fire(`Report does not exist.`);
                }
            },
            async graphResolvedVsPending(is_export = false){
                axios.get('/graphResolvedVsPending', {
                    params: {
                        filter: this.selectedFilter,
                        dateRange: this.selectedDateRange,
                    },
                })
                .then(async response => {
                        this.resolvedVsPending_series = [
                            response.data[0].resolved == null ? 0 : Number(response.data[0].resolved),
                            response.data[0].pending == null ? 0 : Number(response.data[0].pending)]
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },  
            async graphRolesCount(){
                axios.get('/graphRolesCount')
                .then(response => {
                const roles = response.data.map(item => item.name);
                const totals = response.data.map(item => Number(item.total));
                
                this.$set(this.rolesCount_Options.xaxis, 'categories', roles);

                this.rolesCount_series[0].data = totals;
                this.chartKey += 1;
                })
                .catch(error => {
                console.error('Error fetching user role data:', error);
                });
            },
            async getConversationPerMessageType(){
                axios.get('/getConversationPerMessageType', {
                    params: {
                        filter: this.selectedFilter,
                        dateRange: this.selectedDateRange,
                    },
                })
                .then(async response => {
                        let series = response.data.map(e=>{return e.total})
                        let labels = response.data.map(e=>{return e.description})
                        this.messageType_series = series
                        this.messageType_Options.labels = labels

                        this.$refs.chart.updateOptions(this.messageType_Options);
                        this.$refs.chart.updateSeries(this.messageType_series);
                     
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } 
        },
    }
</script>

<style scoped>
body {
    overflow: hidden;
}
.chat-inbox {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.chat-header {
    background-color: #9C4E48;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

.chat-messages {
    padding: 10px;
    max-height: 93vh; 
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
}

.chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.chat-item {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.chat-item:hover {
    background-color: #f0f0f0; /* Adjust the hover background color */
}

.avatar {
    width: 60px; /* Adjust the avatar size as needed */
    height: 60px;
    background-color: #b4b4b4 !important; /* You can add an avatar image here */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
}


.selected-users-row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.selected-user {
    flex: 0 0 calc(25% - 20px); /* Adjust the width for 4 avatars per row */
    margin: 10px;
    text-align: center;
    position: relative; /* Added relative positioning */
    transition: transform 0.3s; /* Added a transition for a smooth effect */
}

.active-avatar .avatar {
    width: 80px; /* Adjust the size of the active avatar as needed */
    height: 80px;
}

.avatar {
    background-color: #b4b4b4 !important; /* You can add an avatar image here */
    border-radius: 50%;
    width: 60px; /* Adjust the avatar size as needed */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
    line-height: 1; /* Added to vertically center the close button */
}

.user-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    max-height: 88vh; 
}

/* Style for the user avatar */
.user-avatar {
    background-color: #b4b4b4 !important; /* You can add an avatar image here */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
}

/* Style for user details */
.user-details {
    /* margin-top: 20px; */
    height: 80vh; 
}

/* Style for user detail items */
.user-detail-item {
    margin-bottom: 10px;
    align-items: start;
}

.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #333; */
    padding: 10px;
    color: #000000;
}

/* Style the search icon */
.search-icon {
    font-size: 24px;
    margin-right: 10px;
}

/* Style the search input */
.search-input {
    flex-grow: 1;
    border: none;
    background-color: transparent;
    color: #000000;
    font-size: 16px;
    padding: 10px;
}

/* Style the search input when focused */
.search-input:focus {
    outline: none;
}

.newMessageHighlight{
    background-color: #eee;
}

.excel-icon{
    position: absolute; 
    top: 10px; 
    right: 10px; 
    font-size: 18px; 
    color: #21A366;
}
.card-header-metrics{
    color: #007BFF;

}

</style>