<!-- CompanyForm.vue -->

<template>
    <div class="container-fluid mt-2">
      <form @submit.prevent="submitForm" class="form-company">
  
        <!-- Company Information Section -->
        <h1 class="h3 mb-3 font-weight-normal text-center">Company Information</h1>
  
        <div class="form-row">
          <!-- Company Name -->
          <div class="form-group col-md-6">
            <label for="name">Company Name</label>
            <input type="text" v-model="company.name" id="name" class="form-control" required>
            <small id="nameHelp" class="form-text text-muted">Enter the name of your company.</small>
          </div>
  
          <!-- Address -->
          <div class="form-group col-md-6">
            <label for="address">Address</label>
            <textarea v-model="company.address" id="address" class="form-control" required></textarea>
            <small id="addressHelp" class="form-text text-muted">Enter the address of your company.</small>
          </div>
        </div>
  
        <div class="form-row">
          <!-- Website -->
          <div class="form-group col-md-6">
            <label for="website">Website</label>
            <input type="text" v-model="company.website" id="website" class="form-control">
            <small id="websiteHelp" class="form-text text-muted">Enter the website of your company (if applicable).</small>
          </div>
  
          <!-- Email -->
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="email" v-model="company.email" id="email" class="form-control" required>
            <small id="emailHelp" class="form-text text-muted">Enter the email address of your company.</small>
          </div>
        </div>
  
        <div class="form-row">
          <!-- Contact Number -->
          <div class="form-group col-md-6">
            <label for="contactNumber">Contact Number</label>
            <input type="text" v-model="company.contact_number" id="contactNumber" class="form-control" required>
            <small id="contactNumberHelp" class="form-text text-muted">Enter the contact number of your company.</small>
          </div>
  
          <!-- Description -->
          <div class="form-group col-md-6">
            <label for="description">Description</label>
            <textarea v-model="company.description" id="description" class="form-control"></textarea>
            <small id="descriptionHelp" class="form-text text-muted">Provide a brief description of your company (optional).</small>
          </div>
        </div>
  
        <!-- Logo Section -->
        <div class="form-group">
          <label for="logo">Logo</label>
          <div class="custom-file">
            <input type="file" ref="logoInput" @change="handleLogoChange" class="custom-file-input" id="logo">
            <label class="custom-file-label" for="logo">Choose file</label>
          </div>
          <small id="logoHelp" class="form-text text-muted">Upload your company logo.</small>
        </div>
  
        <!-- Submit Button -->
        <button class="btn btn-primary btn-block" type="submit">Save</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        company: {
          name: '',
          address: '',
          website: '',
          email: '',
          contact_number: '',
          description: '',
          logo: null,
        },
      };
    },
    methods: {
      submitForm() {
        const formData = new FormData();
        for (const key in this.company) {
          formData.append(key, this.company[key]);
        }
  
        axios.post('/companies', formData)
          .then(response => {
            console.log('Server response:', response.data);
            alert('Form submitted successfully!');
          })
          .catch(error => {
            console.error('Server error:', error.response.data);
            alert('Error submitting form. Please try again.');
          });
      },
      handleLogoChange(event) {
        const file = event.target.files[0];
        this.company.logo = file;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your custom styling here, if needed */
  </style>
  