/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('exceljs');

window.Vue = require('vue');
// const Swal = require('sweetalert2');
import VueChatScroll from 'vue-chat-scroll'
// Import Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import AvatarInitial from 'avatar-initials';
import CryptoJS from 'crypto-js';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BModal } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.use(BootstrapVue);
Vue.use(VueChatScroll);
Vue.use(moment);


Vue.mixin({
    methods: {
      // You can name this method whatever you want
      $formatDate(date, format = 'MMMM Do YYYY, h:mm:ss a') {
        return moment(date).format(format);
      },
      $encryptEmail(email){
        return btoa(email)
        // const secretKey = 'ailaTextSecretKey'; // Replace with your secret key
        // const encryptedEmail = CryptoJS.AES.encrypt(email, secretKey).toString();
        // const base64EncodedEmail = btoa(encryptedEmail);
        // return encodeURIComponent(base64EncodedEmail);
      },
      $createAvatar(name){
        let avatarInstance = new AvatarInitial({})
        let avatar = avatarInstance.generate(name)

        let dataUri = `data:image/png;base64,${avatar.toString('base64')}`;
        return dataUri;
      }
    },
  });
// import Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';

// Vue.use(Vuetify);
// Vue.use(Swal);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <exampnpm runle-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('Chats', require('./components/ChatsComponent.vue').default);
Vue.component('chat-window', require('./components/ChatWindowComponent.vue').default);
Vue.component('client-chat-window', require('./components/ClientChatWindowComponent.vue').default);
Vue.component('metrics-component', require('./components/MetricsComponent.vue').default);
Vue.component('company-registration', require('./components/CompanyRegistrationComponent.vue').default);
Vue.component('company-view', require('./components/CompanyViewComponent.vue').default);
Vue.component('company-table', require('./components/CompanyTableComponent.vue').default);
Vue.component('user-table', require('./components/UserTableComponent.vue').default);
Vue.component('roles-table', require('./components/RolesTableComponent.vue').default);
Vue.component('address-book', require('./components/AddressBookComponent.vue').default);
Vue.component('user-profile-component', require('./components/UserProfileComponent.vue').default);
Vue.component('user-classification', require('./components/UserClassificationTableComponent.vue').default);
Vue.component('department', require('./components/DepartmentTableComponent.vue').default);
Vue.component('message-types-table', require('./components/MessageTypeTableComponent.vue').default);
Vue.component('consent-component', require('./components/ConsentComponent.vue').default);

// Reports
Vue.component('report-unresolved-component', require('./reports_components/UnresolvedComponent.vue').default);
Vue.component('report-resolved-component', require('./reports_components/ResolvedComponent.vue').default);
Vue.component('report-unassigned-component', require('./reports_components/UnassignedComponent.vue').default);
Vue.component('report-new-contacts-component', require('./reports_components/NewContactsComponent.vue').default);
Vue.component('billing-component', require('./components/BillingTableComponent.vue').default);

Vue.component('b-modal', BModal);
Vue.component('apexchart', VueApexCharts)
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const vuetify = new Vuetify();
const app = new Vue({
    el: '#app',
});
