<!-- CompanyDetails.vue -->

<template>
  <div class="container mt-5">
    <div class="card">
      <div class="card-header">
        <h2 class="text-center">{{ company.name }}</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item"><strong>Address:</strong> {{ company.address }}</li>
              <li class="list-group-item"><strong>Website:</strong> {{ company.website || 'N/A' }}</li>
              <li class="list-group-item"><strong>Email:</strong> {{ company.email }}</li>
              <li class="list-group-item"><strong>Contact Number:</strong> {{ company.contact_number }}</li>
              <li class="list-group-item"><strong>Description:</strong> {{ company.description || 'N/A' }}</li>
              <li class="list-group-item"><strong>Schedule:</strong> 
                <b-button v-if="hasPermission('edit company schedule')" variant="secondary" @click="editCompanySchedule"  v-b-modal="'schedule_modal'" size="sm" ><i class="fas fa-clock"></i> Edit Schedule</b-button>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <div v-if="company.logo">
              <h5 class="mt-4 mb-3">Logo:</h5>
              <img :src="'data:image/png;base64,'+company.logo" alt="Company Logo" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      
      <!-- Schedule Modal -->
    <b-modal v-if="scheduleModalVisible" title="Company Schedule" id="schedule_modal" @hidden="resetModal" hide-footer size="lg" ok-only> 
     <h2 class="text-center">Edit Schedule</h2>
      <div class="company-info">
      <div class="details-container">
        <div class="row">
          <div class="col-4 d-flex justify-content-left"><b>Day</b></div>
          <div class="col-4 d-flex justify-content-center"><b>Start Time</b></div>
          <div class="col-4 d-flex justify-content-center"><b>End time</b></div>
        </div>
        <div class="form-row" v-for="(day, index) in days" :key="index">
          <div class="col-4">
            <b-form-checkbox v-model="day.is_active" value="1" unchecked-value="0">{{ dayNameList[day.day] }}</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-timepicker v-model="day.start_time" :disabled="!day.is_active"></b-form-timepicker>
          </div>
          <div class="col-4">
            <b-form-timepicker v-model="day.end_time" :disabled="!day.is_active"></b-form-timepicker>
          </div>
        </div>
        <div class="footer-buttons">
          <b-button class="m-1" variant="primary" @click="saveSchedule">Save</b-button>
          <b-button class="m-1" variant="secondary" @click="cancelSchedule">Cancel</b-button>
        </div>
      </div>
    </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  props: ["userPermissions"],
  data() {
    return {
      company: {},
      schedule:{},
      activeMenuItem: 'company-view',
      days: [
        { name: 'Monday', is_active: 0, start_time: null, end_time: null, day:1 },
        { name: 'Tuesday', is_active: 0, start_time: null, end_time: null, day:2 },
        { name: 'Wednesday', is_active: 0, start_time: null, end_time: null, day:3 },
        { name: 'Thursday', is_active: 0, start_time: null, end_time: null, day:4 },
        { name: 'Friday', is_active: 0, start_time: null, end_time: null, day:5 },
        { name: 'Saturday', is_active: 0, start_time: null, end_time: null, day:6 },
        { name: 'Sunday', is_active: 0, start_time: null, end_time: null, day:0 }
      ],
      dayNameList:[
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      scheduleModalVisible:false,
    };
  },
  mounted() {
    this.fetchCompanyDetails();
  },
  methods: {
    activateMenuItem(menuItem) {
      this.activeMenuItem = menuItem;
    },
    fetchCompanyDetails() {
      axios.get(`/companies/1`)
        .then(response => {
          this.company = response.data.company;
          this.days = response.data.schedule;
        })
        .catch(error => {
          console.error('Error fetching company details:', error.response.data);
        });
    },
    editCompanySchedule(id) {
      // this.selectedCompany = this.companies.find(company => company.id === id);
      this.scheduleModalVisible = true;
    },
    resetModal() {
      this.scheduleModalVisible = false;
      // this.selectedCompany = null;
    },
    saveSchedule(){
      let payload = {
         days:this.days
      }
      axios.post('company/save-schedule',payload).then(response=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.scheduleModalVisible = false
        this.fetchCompanyDetails();
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update company',
          });
      });
    },
    cancelSchedule(){
      this.scheduleModalVisible = false
    },
    hasPermission(permissionName) {
      return this.userPermissions.some(
        (permission) => permission.name === permissionName
      );
    },
  },
};
</script>

<style scoped>
/* Add your custom styling here, if needed */
.footer-buttons {
  margin-top:20px;
  display: flex;
  justify-content: flex-end;
}
</style>
