<template>
    <div class="row m-2">
        <div class="col-12">
            <div class="p-3 header-button-box" style="height: max-content;">
                <div style="display: flex; align-items: center; width: 100%; justify-content: space-between;">
                    <div style="display: flex; align-items: center;">
                        <div style="font-weight: bold;">{{ !!recipient ? recipient.name : '' }}</div>
                        <!-- <div style="color: grey; font-size: 14px;" class="m-2">{{ claimedText }}</div> -->
                    </div>
                    <div style="display: flex;">
                        <button v-if="hasPermission('start conversation')"
                            style="border: none; border-right: solid black 1px; cursor: pointer;" class="btn m-2 pr-2"
                            @click="sendSms()" :disabled="isDisabledStartConvo">
                            <i class="fa fa-message" aria-hidden="true"></i>
                            <span>{{ tabName == 'coordinator' && !!recipient ? 'Ping ' + recipient.name : 'Start Conversation'}}</span>
                        </button>
                        <button v-if="tabName != 'coordinator' && hasPermission('claim conversation')"
                            style="border: none; cursor: pointer;" :class="'btn m-2 ' + claimedColor"
                            @click="claimMessage()" :disabled="isDisabledClaimed">
                            <i class="fa fa-user-plus" aria-hidden="true"></i>
                            <span>Claim</span>
                        </button>
                        <button v-if="tabName != 'coordinator' && hasPermission('transfer conversation')"
                            style="border: none; cursor: pointer;" class="btn m-2" data-toggle="modal"
                            data-target="#exampleModalCenter" :disabled="isDisabledResolve"
                            @click="openTransferMessage()">
                            <i class="fa fa-exchange" aria-hidden="true"></i>
                            <span>Transfer</span>
                        </button>
                        <button v-if="tabName != 'coordinator' && hasPermission('close conversation')"
                            style="border: none; cursor: pointer;" :class="'btn m-2 ' + resolvedColor"
                            @click="closeMessage()" :disabled="isDisabledResolve">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <span>Close</span>
                        </button>
                        <button style="border: none; cursor: pointer;" class="btn m-2">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="max-height:63vh">
            <div class="card transparent-card" style="max-height:63vh">
                <div class="card-body transparent-card p-0">
                    <ul class="list-unstyled" style="max-height:100%; overflow-y:scroll"
                        v-chat-scroll="{ always: false, smooth: true }">
                        <li v-for="message in allMessage">

                            <p>
                                <hr>
                            <p class="text-center">{{ formatDateTime(message.started_date) }}</p>
                            <p v-if="!!message.claimed_by" class="text-center claimed-box p-0 m-4" role="alert">Claimed
                                by: {{ message.claimed_by_name }}</p>
                            <p v-for="chatMessage in message.message"
                                :class="chatMessage.user_id != user.id ? 'px-2 text-left' : 'px -2 text-right'">

                                <strong>{{ chatMessage.name }}</strong>
                                <br />
                                <img v-if="chatMessage.user_id != user.id"
                                    :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`"
                                    alt="avatar" class="rounded-circle shadow-1-strong" width="35">

                                <span v-if="checkIfHasString(chatMessage.message)"
                                    :class="chatMessage.user_id != user.id ? 'bg-light message-box-other px-2 py-1' : 'bg-light message-box px-2 py-1'">
                                    {{ chatMessage.message }}</span>
                                <span v-else><img width="50%" :src="chatMessage.message" alt="Image"
                                        @click="openModal(chatMessage.message)"></span>

                                <img v-if="chatMessage.user_id == user.id"
                                    :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`"
                                    alt="avatar" class="rounded-circle shadow-1-strong" width="35">
                            </p>
                            <p v-if="!!message.resolved_by" class="text-center p-0 m-4 resolved-box" role="alert">
                                Resolved by: {{ message.resolved_by_name }}</p>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <span class="text-muted" v-if="typingUser">{{ typingUser.name }} is styping...</span>
        </div>
        <div class="col-12 input-with-icon">
            <b-input-group>
                <!-- Input field -->
                <b-form-input :disabled="allowType" v-if="!showFile" @keydown="getTypingEvent"
                    @keyup.enter="saveMessage" v-model="newMessage" type="text" name="message"
                    placeholder="Enter your message..." class="form-control"
                    style="border-start-start-radius: 10px; padding: 10px"></b-form-input>

                <!-- Suffix icons -->
                <b-input-group-append>
                    <!-- Clickable icon 1 -->
                    <b-input-group-text v-if="!showFile && hasPermission('send image')">
                        <i class="fas fa-image" @click="showFile = true"></i>
                    </b-input-group-text>

                    <!-- Clickable icon 2 -->
                    <b-input-group-text v-if="!showFile">
                        <i class="fas fa-paper-plane" @click="saveMessage"></i>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group>
                <b-form-file v-if="showFile" v-model="newMessageFile" :state="Boolean(newMessageFile)"
                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                    class="mr-2"></b-form-file>

                <!-- Add a button to clear the selected file -->
                <b-button v-if="showFile" variant="danger" size="sm" @click="showFile = false">
                    Clear
                </b-button>
                <b-button v-if="showFile" variant="primary" size="sm" @click="saveMessage" class="ml-1">
                    Send
                </b-button>
            </b-input-group>
        </div>
        <BModal ref="transferModal" size="lg" hide-footer title="Transfer to:" @hidden="closeModal" centered>


            <b-form-select v-model="transfer.selectedCoordinator" :options="allCoordinators">

            </b-form-select>
            <!-- v-if="item.id != user.id && item.role.id != 2">{{ item.name }} -->
            <b-form-group label="Do you want to include yourself in the conversation?" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="transfer.selectedOption" :aria-describedby="ariaDescribedby" name="some-radios"
                    value="y">Yes</b-form-radio>
                <b-form-radio v-model="transfer.selectedOption" :aria-describedby="ariaDescribedby" name="some-radios"
                    value="n">No</b-form-radio>
            </b-form-group>


            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="transferSession">Transfer</button>
        </BModal>
    </div>
</template>

<!-- <script src="https://js.pusher.com/8.2.0/pusher.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script> -->
<script>
import Echo from 'laravel-echo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import Dropdown from '../custom_components/Dropdown.vue';

export default {
    components: {
        Dropdown
    },
    props: ['user', 'chatSessionId', 'recipient', 'room_id', 'selected_tab', 'userPermissions', 'sessionId'],
    data() {
        return {
            allMessage: [],
            newMessage: '',
            users: [],
            typingTimer: 'false',
            typingUser: '',
            recipient_id: '',
            params: {
                recipient_id: null,
                timestamp: null,
                session_id: null,
            },
            claimedText: '',
            session_id: null,
            allowType: false,
            isDisabledResolve: true,
            isDisabledClaimed: true,
            isDisabledTransfer: true,
            isDialogOpen: false,
            dialogTitle: "Custom Dialog",
            dropdownItems: [
                { label: "Item 1", value: "item1" },
                { label: "Item 2", value: "item2" },
                { label: "Item 3", value: "item3" }
            ],
            claimedColor: null,
            resolvedColor: null,
            selected: null,
            showModal: false,
            selectedImage: null,
            sentImage: null,
            newMessageFile: '',
            showFile: false,
            allCoordinators: [],
            transfer: {
                selectedCoordinator: null,
                selectedOption: null,
            },
            tabName: null
        }
    },
    async mounted() {

        // this.getAllUsers();
        this.getAllCoordinators();
        // await this.getParameters()
        // await this.getMessages();
        // axios.get('get-twilio-billing')
        window.Echo.join('chats')
            .listenForWhisper('typing', user => {
                if (this.recipient.id == user.id) {
                    this.typingUser = user;
                    if (this.typingUser) {
                        clearTimeout(this.typingTimer);
                    }
                    this.typingTimer = setTimeout(() => {
                        this.typingUser = false;
                    }, 1500)
                }
            })
            .listen('SendMessageEvent', (event) => {
                const id = _.findIndex(this.allMessage, ['id', Number(event.message.session_id)]);
                //  this.allMessage.findIndex(obj => obj.id === event.message.session_id);
                Object.assign(event.message, {
                    name: event.user.name
                })
                this.allMessage[id].message.push(event.message)
                this.allMessage[id].user = event.user
                // this.claimedColor = 'btn-warning'
                // if ((event.message.session_id == this.params.session_id)){
                // this.allMessage.push({message:event.message});
                // }
            })
            .leaving((user) => {
                console.log(user)
                if (this.recipient.id == user.id) {
                    Swal.fire(user.name, ' Leave the conversation', 'warning')
                }
            });
        window.Echo.join('claim-chats')
            .listen('ClaimMessageEvent', (event) => {
                //    if(event.session_id == this.session_id){
                this.getMessages();
                //    }
            })
    },
    methods: {
        async getMessages() {
            let params = {
                params: {
                    selected_user: this.params.recipient_id,
                    session_id: this.sessionId
                }
            }
            await axios.get('messages', params).then(async response => {
                this.allMessage = response.data;
                const lastIndex = this.allMessage[this.allMessage.length - 1];
                this.isDisabledClaimed = !!lastIndex.claimed_by ? true : false
                this.isDisabledTransfer = true
                this.isDisabledResolve = !!lastIndex.claimed_by && !!lastIndex.resolved_by ? true : false
                this.claimedColor = lastIndex.claimed_by == null && lastIndex.claimed_by == null ? 'btn-warning' : null
                this.resolvedColor = lastIndex.resolved_by == null && !!lastIndex.claimed_by ? 'btn-success' : null
                this.allowType = !!lastIndex.claimed_by ? false : true
                if (!!lastIndex.resolved_by) {
                    this.isDisabledClaimed = true
                    this.isDisabledTransfer = true
                    this.isDisabledResolve = true
                    this.allowType = true
                }
                this.session_id = lastIndex.id
                console.log(lastIndex)
                await this.$emit('update-notification-count', this.session_id)
                if (!!lastIndex.claimed_by && !!lastIndex.resolved_by) {
                    this.$emit('update-badge-status', null, null, this.params.recipient_id)
                }
                else if (!!lastIndex.claimed_by && lastIndex.resolved_by == null) {
                    this.$emit('update-badge-status', 'unresolved', 'badge-info', this.params.recipient_id)
                }
                else if (lastIndex.claimed_by == null) {
                    this.$emit('update-badge-status', 'unassigned', 'badge-danger', this.params.recipient_id)
                }
                // if(lastIndex.claimed_by != null && Number(lastIndex.claimed_by) != Number(this.user.id)){
                //     this.$emit('update-inbox',true)
                // }


            })
        },
        saveMessage() {
            if (!!this.newMessage || !!this.newMessageFile) {
                // this.allMessage.push({
                //     user:this.user,
                //     message:this.newMessage,
                // });
                const lastIndex = this.allMessage[this.allMessage.length - 1];
                // const id = this.allMessage.findIndex(obj => obj.id === this.session_id);

                let fileHolder = null;

                if (this.newMessageFile) {
                    const reader = new FileReader();

                    // Create a promise that resolves when the onload event is triggered
                    const onloadPromise = new Promise((resolve) => {
                        reader.onload = (event) => {
                            fileHolder = event.target.result;

                            resolve(fileHolder);
                        };
                    });

                    // Read the selected file as a data URL (base64)
                    reader.readAsDataURL(this.newMessageFile);

                    // Wait for the promise to resolve before proceeding
                    // await onloadPromise;

                    // Now fileHolder has the correct value
                    onloadPromise.then(res => {
                        this.sentImage = res;
                        lastIndex.message.push({
                            message: this.newMessageFile ? this.sentImage : this.newMessage,
                            user_id: this.user.id,
                            name: this.user.name,
                        });
                    })

                }
                else {
                    lastIndex.message.push({
                        message: this.newMessageFile ? this.sentImage : this.newMessage,
                        user_id: this.user.id,
                        name: this.user.name,
                    });
                }


                lastIndex.user = this.user

                const formData = new FormData();
                formData.append('message', !this.showFile ? this.newMessage : this.newMessageFile)
                formData.append('recipient_id', this.params.recipient_id)
                formData.append('session_id', lastIndex.id)

                axios.post('/messages', formData).then(response => {
                    this.newMessage = '';
                    this.showFile = false
                    this.newMessageFile = ''
                })
            }
        },
        getTypingEvent() {
            window.Echo.join('chats')
                .whisper('typing', this.user)
        },
        // getAllUsers(){
        //     axios.get('getAllUsers').then(response=>{
        //         this.users = response.data
        //     })
        // },            
        async getParameters() {
            const session_id = this.chatSessionId
            this.params.recipient_id = session_id.split('-')[0];
            this.params.timestamp = session_id.split('-')[1];
            this.params.session_id = session_id
        },
        testMessage() {
            if (!!this.newMessage) {
                // this.allMessage.push({
                //     user:this.user,
                //     message:this.newMessage,
                // });
                axios.post('/client-start-chat', {
                    message: this.newMessage,
                }).then(response => {
                    this.newMessage = '';
                })
            }
        },
        claimMessage() {
            Swal.fire({
                title: 'Are you sure you want to claim this conversation?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire("Conversation Claimed Successfully", "", "success")
                    this.claimedText = `${this.user.name} claimed this conversation`;
                    axios.post('/claim-session', {
                        session_id: this.session_id,
                    }).then(response => {
                        this.allowType = false
                        this.claimedColor = null
                        this.resolvedColor = 'btn-success'
                        this.$emit('update-inbox', false)
                        this.getMessages()
                    })

                }
            })
        },
        closeMessage() {
            Swal.fire({
                title: 'Are you sure you want to close this conversation?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resolve Conversation',
                cancelButtonText: 'Yes, No response from caregiver',
                cancelButtonColor: 'grey',
                allowOutsideClick: false,
                showCloseButton: true,
            }).then((result) => {
                let close_tag = 0

                if (result.isConfirmed) {
                    close_tag = 1
                }
            else if(result.dismiss == 'cancel'){
                    close_tag = 2
                }
                else if(result.dismiss == 'close'){
                    return false;
                }

                if(close_tag != 0){
                    axios.post('/close-session', {
                    session_id: this.session_id,
                    close_tag: close_tag
                    }).then(response => {
                        this.claimedText = `${this.user.name} closed this conversation`;
                        this.allowType = false
                        this.resolvedColor = null
                        this.getMessages();
                        Swal.fire("Conversation Closed Successfully", "", "success")
                        this.$emit('update-inbox', true)
                        this.$emit('update-badge-status', null, null, this.params.recipient_id)
                    })
                }
                
            })
        },
        autoCloseMessage() {
            axios.post('/close-session', {
            session_id: this.session_id,
            close_tag: 1
            }).then(response => {
                this.claimedText = `${this.user.name} closed this conversation`;
                this.allowType = false
                this.resolvedColor = null
                this.getMessages();
                Swal.fire("Conversation Closed Successfully", "", "success")
                this.$emit('update-inbox', true)
                this.$emit('update-badge-status', null, null, this.params.recipient_id)
            })
        },
        formatDateTime(date) {
            return this.$formatDate(date)
        },
        openDialog() {
            this.isDialogOpen = true;
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
        async sendSms() {

            let { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Are you sure you want to Start a conversation?',
                inputValue: !!this.user.preset_message ? this.user.preset_message : '',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            }).then((result) => {
                console.log(result)
                if (result.isConfirmed) {
                    // encodeURIComponent(encryptedEmail)
                    const currentTimestamp = new Date().getTime();
                    let encodedEmail = this.$encryptEmail(this.recipient.email)
                    let encodedFrom = this.$encryptEmail(this.user.email)
                    let encodedTs = this.$encryptEmail(currentTimestamp)
                    const origin = window.location.origin;
                    const url = `${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}'`;
                    axios.post(`send-sms-to-client`,
                        {
                            message_url: url,
                            body: result.value,
                            recipient_email: this.recipient.email,
                            sender_email: this.user.email
                        }
                    ).then(response => {
                        Swal.fire('Message sent successfully', '', 'success')
                    })
                }
            })

            // Swal.fire({
            //     title: 'Are you sure you want to Start a conversation?',
            //     text: "",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Confirm',
            //     reverseButtons: true
            //     }).then((result) => {
            //     if (result.isConfirmed) {
            //         // encodeURIComponent(encryptedEmail)
            //         const currentTimestamp = new Date().getTime();
            //         let encodedEmail = this.$encryptEmail(this.recipient.email)
            //         let encodedFrom = this.$encryptEmail(this.user.email)
            //         let encodedTs = this.$encryptEmail(currentTimestamp)
            //         const origin = window.location.origin;
            //         const url = `'${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}''`;
            //         console.log(url)
            //         axios.post(`send-sms-to-client`,
            //               {  message_url:url}
            //         ).then(response=>{
            //             alert('message sent')
            //         })
            //     }
            // })
        },
        checkIfHasString(message) {
            if (message.includes('data:')) {
                return false
            }
            return true
        },
        openModal(imageSrc) {
            this.selectedImage = imageSrc;
            this.showModal = true;
        },
        closeModal() {

            const modal = this.$refs.transferModal
            if (modal) {
                // You can use either show or toggle based on your requirements

                modal.hide();
                // Alternatively, you can use modal.toggle();
            }
        },
        getSelectedRoomMessages() {
            let params = {
                params: {
                    id: this.room_id
                }
            }
            axios.get('getSelectedRoomMessages', params).then(async response => {
                this.allMessage = response.data
                const lastIndex = this.allMessage[this.allMessage.length - 1];
                this.isDisabledClaimed = !!lastIndex.claimed_by ? true : false
                this.isDisabledTransfer = true
                this.isDisabledResolve = !!lastIndex.claimed_by && !!lastIndex.resolved_by ? true : false
                this.claimedColor = lastIndex.claimed_by == null && lastIndex.claimed_by == null ? 'btn-warning' : null
                this.resolvedColor = lastIndex.resolved_by == null && !!lastIndex.claimed_by ? 'btn-success' : null
                this.allowType = !!lastIndex.claimed_by ? false : true
                if (!!lastIndex.resolved_by) {
                    this.isDisabledClaimed = true
                    this.isDisabledTransfer = true
                    this.isDisabledResolve = true
                    this.allowType = true
                }
                await this.$emit('update-notification-count', this.session_id)
                if (!!lastIndex.claimed_by && !!lastIndex.resolved_by) {
                    this.$emit('update-badge-status', null, null, this.params.recipient_id)
                }
                else if (!!lastIndex.claimed_by && lastIndex.resolved_by == null) {
                    this.$emit('update-badge-status', 'unresolved', 'badge-info', this.params.recipient_id)
                }
                else if (lastIndex.claimed_by == null) {
                    this.$emit('update-badge-status', 'unassigned', 'badge-danger', this.params.recipient_id)
                }
                this.session_id = lastIndex.id
            });
        },
        transferSession() {
            axios.post('transfer-session', {
                isIncluded: this.transfer.selectedOption,
                transferToId: this.transfer.selectedCoordinator,
                session_id: this.session_id,
            }).then(response => {
                Swal.fire("Conversation Transferred Successfully", "", "success")
                this.autoCloseMessage();
                this.closeModal();
                this.$emit('update-inbox', true)
                // this.$emit('update-badge-status',null,null,this.params.recipient_id)
            });
        },
        getAllCoordinators() {
            axios.get('getAllCoordinators').then(response => {
                this.allCoordinators = response.data
            });
        },
        hasPermission(permissionName) {
            return this.userPermissions.some(permission => permission.name === permissionName);
        },
        openTransferMessage() {

            this.showModal = true
            const modal = this.$refs.transferModal
            if (modal) {
                // You can use either show or toggle based on your requirements

                modal.show();
                // Alternatively, you can use modal.toggle();
            }
        }
    },
    watch: {
        async chatSessionId(val) {
            console.log(val)
            await this.getParameters();
            if (!!this.room_id) {
                await this.getSelectedRoomMessages();
            } else {
                await this.getMessages();
            }
        },
        async selected_tab(val) {
            this.tabName = val
            // this.recipient = null
            // this.chatSessionId = null
            this.allMessage = []
        },
    },
    computed: {
        isDisabledStartConvo() {
            if (this.tabName == 'coordinator') {
                this.allowType = true
                return false
            }
            else if (this.isDisabledClaimed == true &&
                this.isDisabledTransfer == true &&
                this.isDisabledResolve == true &&
                this.room_id == null
            ) {
                return false
            }
            else {
                return true
            }
        }
    }
}
</script>

<style>
.chat-inbox {
    width: 300px;
    /* Adjust the width as needed */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.card {
    height: 100%;
}

.chat-header {
    background-color: #9C4E48;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

.transparent-card {
    padding: 20px;
    /* Add padding to the card for spacing */
    background: transparent;
    border: none;
    height: 70vh;
}

.chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.chat-item {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.chat-item:hover {
    background-color: #f0f0f0;
    /* Adjust the hover background color */
}

.avatar {
    width: 60px;
    /* Adjust the avatar size as needed */
    height: 60px;
    background-color: #9C4E48;
    /* You can add an avatar image here */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
}

.active-convo {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
}

.selected-users-row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.selected-user {
    flex: 0 0 calc(25% - 20px);
    /* Adjust the width for 4 avatars per row */
    margin: 10px;
    text-align: center;
    position: relative;
    /* Added relative positioning */
    transition: transform 0.3s;
    /* Added a transition for a smooth effect */
}

.active-avatar .avatar {
    width: 80px;
    /* Adjust the size of the active avatar as needed */
    height: 80px;
}

.avatar {
    width: 60px;
    /* Adjust the avatar size as needed */
    height: 60px;
    background-color: #9C4E48;
    /* You can add an avatar image here */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
    line-height: 1;
    /* Added to vertically center the close button */
}


.send-icon {
    position: absolute;
    top: 50%;
    right: 25px;
    /* Adjust the right distance as needed */
    transform: translateY(-50%);
    color: #555;
    /* Icon color */
    cursor: pointer;
}

.message-box {
    width: auto;
    display: inline-block;
    border-radius: 20px;
    -webkit-box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    -moz-box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    border: solid thin;
}

.message-box-other {
    width: auto;
    display: inline-block;
    border-radius: 20px;
    -webkit-box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    -moz-box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    border: solid thin;
}

.resolved-box {
    width: auto;
    font-weight: bold;
    border-radius: 20px;
    -webkit-box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
    -moz-box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
    box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
}

.claimed-box {
    width: auto;
    font-weight: bold;
    border-radius: 20px;
    -webkit-box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
    -moz-box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
    box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
}

.header-button-box {
    width: auto;
    border-radius: 20px;
    -webkit-box-shadow: 7px 7px 0px 0px #96CEB4;
    -moz-box-shadow: 7px 7px 0px 0px #96CEB4;
    box-shadow: 7px 7px 0px 0px #96CEB4;
    border: solid thin;
}

.dark-message {
    background-color: #E6E8EC;
}

.custom-active-tab {
    border-radius: 20px;
    font-weight: bold;
    background-color: white;
    -webkit-box-shadow: 7px 7px 0px 0px #96CEB4;
    -moz-box-shadow: 7px 7px 0px 0px #96CEB4;
    box-shadow: 7px 7px 0px 0px #96CEB4;
    border: solid thin;
    color: #000;
}
</style>