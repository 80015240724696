<template>
  <div class="mt-4">
    <h2>Roles & Permissions</h2>
    <b-row>
      <div class="form-group col-md-3">
        <b-row class="my-2">
      <b-col>
        <b-button  v-if="hasPermission('create user')" @click="addUser()" v-b-modal="'add_update_modal'" variant="primary" size="sm">+ ADD</b-button>
      </b-col>
      <b-col>
        
      </b-col>
    </b-row>
      </div>
      <div class="form-group col-md-3">
        <!-- <b-form-select v-model="role.user_role_id" :options="user_roles.selection">
        <template #first>
            <b-form-select-option value="null" disabled>-- Company --</b-form-select-option>
          </template>
        </b-form-select> -->
      </div>
      <div class="form-group col-md-6">
        <b-input-group size="md">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search Role"
            @input="getAllRolesPermissionTable"
          ></b-form-input>

          <b-input-group-append>
            <b-button  @click="clearFilters">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-row>
    <table v-if="!!roles && !!permissions" class="role-table">
      <thead>
        <tr>
          <th>Permission</th>
          <th v-for="role in roles" :key="role.id">{{ role.name }}</th>
        </tr>
      </thead>
      <tbody v-for="(s,i) in samp" :key="i">
            <b>{{ i }}</b>
            <tr v-for="permission in s" :key="permission.id" >
              <td class="pl-2">{{ permission.name }}</td>
              <td v-for="role in roles" :key="role.id" :title="permission.description">
                <input type="checkbox" :checked="roleHasPermission(role, permission)" @change="getData(role,permission)">
              </td>
            </tr>       
      </tbody>
    </table>
    <!-- <b-table striped bordered :items="permissions" :fields="fields">
      <template v-slot:cell(permission)="data">
          
      </template>
      <template v-for="role in roles" v-slot:[`cell(${role.id})`]="data">
        <input type="checkbox" v-model="rolePermissions[role.id][data.item.id]" @change="updatePermission(role.id, data.item.id)">
      </template>
    </b-table> -->
    <!-- <b-table striped hover :items="roles" :fields="header"
      :busy="loading"
      :per-page="perPage"
      stacked="md"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(logo)="data">
        <b-img width="50px" rounded :src="'data:image/png;base64,'+data.item.logo"  alt="User Logo"></b-img>
      </template>
      <template #cell(action)="data">
          <b-button v-if="hasPermission('view role')" variant="warning" @click="viewModal(data.item.id,'',$event.target)" v-b-modal="'view_modal'" size="sm"><i class="fas fa-eye"></i></b-button>
          <b-button v-if="hasPermission('edit role')" variant="primary" @click="editModal(data.item.id)" v-b-modal="'add_update_modal'" size="sm"><i class="fas fa-edit"></i></b-button>
          <b-button v-if="hasPermission('delete role')" variant="danger" @click="deleteModal(data.item.id)"  v-b-modal="'delete_modal'" size="sm" ><i class="fas fa-role-times"></i></b-button>
      </template>
    </b-table> -->
    <!-- <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row> -->
  
    <!-- View Modal -->
    <b-modal v-if="viewModalVisible" title="View User" id="view_modal" @hidden="resetModal" hide-footer size="md" ok-only> 
     <h2 class="text-center"> {{ selectedRole.name }}</h2>
      <div class="role-info">
      <!-- <div class="card align-items-center">
        <b-img style="width: 500px;" fluid rounded center thumbnail :src="'data:image/png;base64,'+selectedRole.logo" alt="User Logo"></b-img>
      </div> -->
      <div class="details-container text-center">
        <p><strong>Name:</strong> {{ selectedRole.name }}</p>
        <!-- <p><strong>Email:</strong> {{ selectedRole.email }}</p>
        <p><strong>Contact Number:</strong> {{ selectedRole.contact_number }}</p>
        <p><strong>Address:</strong> {{ selectedRole.address }}</p> -->
      </div>
    </div>
    </b-modal>

    <!-- Delete Modal -->
    <BModal v-if="deleteModalVisible" title="Delete User" id="delete_modal" @hidden="resetModal" hide-footer>
      <p>Are you sure you want to set inactive the selected role??</p>
      <b-button variant="danger" @click="confirmDelete">Yes, Delete</b-button>
    </BModal>

    <BModal  v-if="addUpdateModalVisible" id="add_update_modal" @hidden="resetModal" :title="addUpdateModal.title" size="md" hide-footer> 
      <div class="container-fluid mt-2">
        <form @submit.prevent="addUpdateModal.button == 'Save' ? submitForm() : updateUser()" class="form-role">
          <!-- User Information Section -->
          <h1 class="h3 mb-3 font-weight-normal text-center">Role Information</h1>

          <div class="form-row">
            <!-- User Name -->
            <div class="form-group col-md-12">
              <label for="name">Name</label>
              <input type="text" v-model="role.name" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Role Name.</small>
            </div>
            <div class="form-group col-md-12">
              <label for="name">Role Title</label>
              <input type="text" v-model="role.role_title" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Role Title.</small>
            </div>
            <div class="form-group col-md-12">
              <label for="name">Description</label>
              <input type="text" v-model="role.description" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Role Description.</small>
            </div>
          </div>
          <!-- Submit Button -->
          <button class="btn btn-primary btn-block" type="submit">{{addUpdateModal.button}}</button>
        </form>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  props: ['userPermissions'],
  data() {
    return {
      samp:[],
      permissions:[],
      roles: [],
      viewModalVisible: false,
      addUpdateModalVisible: false,
      deleteModalVisible: false,
      selectedRole: null,
      role: {
        id: '',
        name: '',
        description: '',
        role_title: '',
        contact_number: '',
        email: '',
        address: '',
        user_role_id:null,
        company_id:null
      },
      header:[
        { key: 'name', label: 'Name' },
        { key: 'action', label: 'Actions' },
      ],
      addUpdateModal:{
        title:'Add User',
        button:'Save',
        function:'submitForm'
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true,
      companies:{
        selected:0,
        selection:[]
      },
      user_roles:{
        selected:0,
        selection:[]
      },
      filters:{
        company_id:null
      },
      rolePermissions:[],
      fields: [
      ],
      rolePermissions:{}
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getAllRolesAndPermissions();
    // this.getAllRolesPermissionTable();
    // this.dropdownCompanies();
    // this.dropdownUserRoles();
  },
  methods: {
    roleHasPermission(role, permission) {
      if(_.find(role.permissions, { 'id': permission.id })){
        return true
      }
      return false
    },
    getData(role, permission) {
      let payload = {
         role_id:role.id,
         permission_name:permission.name
      }
      axios.post('/roles/updateRolesAndPermission',payload).then(response=>{

      })
     
    },
    getAllRolesAndPermissions(){
      axios.get('/getAllRolesAndPermissions').then(response=>{
        this.roles = response.data.roles
        this.permissions = response.data.permissions
        this.samp = _.groupBy(this.permissions, 'module')
        console.log(_.groupBy(this.permissions, 'module'))
        // let newFields = []
        // this.roles.forEach(e=>{
        //   this.fields.push(
        //     {
        //       key:e.name,
        //       label:e.name
        //     }
        //   )
        // })
        // let arr = []
        // console.log(response.data.permissions)
        // response.data.permissions.forEach(e=>{
        //   arr.push(_.invert(e))
        // })
        // this.permissions = arr
        // this.permissions.forEach(e=>{
            
        // })
        // console.log(this.permissions)
        // // console.log(this.fields)
      })
    },
    getAllRolesPermissionTable() {
      this.roles = []
      this.loading = true
      axios.get('/getAllRolesTable',{
        params: {
            perPage: this.perPage,
            currentPage: this.currentPage,
            filter: this.filter,
         },
      })
        .then(response => {
          this.roles = response.data.data;
          this.loading = false
          this.totalRows = response.data.total
          console.log(this.roles)
        })
        .catch(error => {
          console.error('Error fetching roles:', error);
        });
    },
    viewModal(id, index, button) {
      this.addUpdateModal.title = 'Add User'
      this.addUpdateModal.button = 'Save'
      this.selectedRole = this.roles.find(role => role.id === id);
      this.viewModalVisible = true;
    },
    editModal(id) {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Edit User'
      this.addUpdateModal.button = 'Update'
      this.addUpdateModal.function = 'updateUser'
      this.selectedRole = this.roles.find(role => role.id === id);
      this.role = this.selectedRole

    },
    addUser() {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Add User'
      this.addUpdateModal.button = 'Save'
      this.addUpdateModal.function = 'submitForm'
      this.role = { ...this.$options.data().role };
    },
    updateUser() {
      const formData = new FormData();
      for (const key in this.selectedRole) {
        formData.append(key, this.selectedRole[key]);
      }
      axios.put(`/roles/${this.selectedRole.id}`, this.selectedRole)
      .then(response => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.addUpdateModalVisible = false
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update role',
          });
      });
      
    },
    deleteModal(id) {
      this.selectedRole = this.roles.find(role => role.id === id);
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      this.deleteModalVisible = false;
      const id = this.selectedRole.id;
      axios.post(`roles/delete-confirm/${id}`)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          this.getAllRolesPermissionTable();
          this.getAllRolesAndPermissions();
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to delete role',
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false
      this.selectedRole = null;
    },
    submitForm() {
        const formData = new FormData();
        for (const key in this.role) {
          formData.append(key, this.role[key]);
        }
  
        axios.post('/roles', formData)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            this.getAllRolesPermissionTable()
            this.getAllRolesAndPermissions();
            this.addUpdateModalVisible = false
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.error || 'Failed to save role',
            });
          });
      },
      handleLogoChange(event) {
        const file = event.target.files[0];
        this.role.logo = file;
      },
      handleUpdateLogoChange(event) {
        const file = event.target.files[0];
        this.selectedRole.logo = file;
      },
      dropdownCompanies(){
        axios.get('dropdowns/getAllCompanies').then(response=>{
          this.companies.selection = response.data
        })
      },
      dropdownUserRoles(){
        axios.get('dropdowns/getAllUserRoles').then(response=>{
          this.user_roles.selection = response.data
        })
      },
      clearFilters(){
        this.filter = null
        this.filters.company_id = null
      },
      hasPermission(permissionName) {
          return this.userPermissions.some(permission => permission.name === permissionName);
      },
      updatePermission(roleId, permissionId) {
      const hasPermission = this.rolePermissions[roleId][permissionId];
      // Send the updated data to the server
      axios.post('/update-permission', { roleId, permissionId, hasPermission })
        .then(response => {
          // Handle response if necessary
        })
        .catch(error => {
          console.error('Error updating permission:', error);
        });
    }
      
  },
  watch:{
    currentPage(val){
      this.getAllRolesPermissionTable()
      this.getAllRolesAndPermissions();
    }
  }
};
</script>
<style scoped>
.role-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.role-table th, .role-table td {
  border: 1px solid #ddd;
  text-align: center;
}

.role-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
</style>