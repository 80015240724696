<template>
  <div class="container-fluid mt-4">
    <!-- Filters -->
    <b-row>
      <div class="form-group col-md-3 mt-3">
        <b-form-select
          v-model="companies.selected"
          :options="companies.selection"
          @input="getResults"
          size="sm"
          placeholder="Select Company"
          hint="Select Company"
        >
          <template #first>
            <b-form-select-option value="0" disabled>Select Company</b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="form-group col-md-9">
        <b-input-group size="md">
          <div class="col">
            <div class="d-flex">
              <b-form-datepicker
                size="sm"
                v-model="selectedDateRange.start"
                class="mr-2"
                placeholder="Start Date"
              />
              <b-form-datepicker
                size="sm"
                v-model="selectedDateRange.end"
                class="mr-2"
                placeholder="End Date"
              />
              <b-button class="mx-2" size="sm" @click="getResults">Apply</b-button>
            </div>
          </div>
        </b-input-group>
      </div>
    </b-row>
    <!-- <b-button variant="primary" type="submit">Filter</b-button> -->

    <!-- Billing Summary -->
    <b-row>
      <b-col class="my-4">
        <b-card>
          <h5 class="card-title">Total Messages</h5>
          <span
            ># of Messages:
            <h3>{{ billingSummary.totalMessages }}</h3></span
          >
        </b-card>
      </b-col>
      <b-col class="my-4">
        <b-card>
          <h5 class="card-title">Total Cost</h5>
          <span
            >Cost (USD):
            <h3>$ {{ billingSummary.totalCost.toFixed(2) }}</h3></span
          >
        </b-card>
      </b-col>
      <b-col class="my-4">
        <b-card>
          <h5 class="card-title">Cost/Segment</h5>
          <span
            >Cost (USD):
            <h3>$ {{ billingSummary.costPerMessage }}</h3></span
          >
        </b-card>
      </b-col>
    </b-row>

    <!-- Graphs -->
    <b-card class="my-4">
      <h5 class="card-title">Billing Graph</h5>
      <apexchart
        ref="chart"
        type="line"
        height="350"
        width="99%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </b-card>

    <!-- Action Buttons -->
    <b-button-group class="mb-4">
      <b-button variant="success" @click="downloadExcel">Download Excel</b-button>
      <b-button variant="danger" @click="saveAsPDF">Save as PDF</b-button>
    </b-button-group>

    <!-- Billing Data Table -->
    <b-table
      :items="billingData"
      :fields="billingFields"
      striped
      hover
      responsive="sm"
    >
    <template #cell(cost)="data">
        {{ data.item.cost * billingSummary.costPerMessage }}
      </template>
    </b-table>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
} from "bootstrap-vue";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
const ExcelJS = require("exceljs");
import Swal from "sweetalert2";
export default {
  components: {
    BTable,
    BCard,
    BButton,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      companies: {
        selected: 0,
        selection: [],
      },
      selectedDateRange: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      filters: {
        companyName: "",
        startDate: null,
        endDate: null,
      },
      billingSummary: {
        totalMessages: 1200,
        totalCost: 1200 * 0.0079,
        costPerMessage:0
      },
      billingData: [
        {
          date: "2024-08-01",
          companyName: "Company A",
          messageCount: 300,
          cost: 300 * 0.0079,
        },
        {
          date: "2024-08-02",
          companyName: "Company B",
          messageCount: 450,
          cost: 450 * 0.0079,
        },
        {
          date: "2024-08-03",
          companyName: "Company C",
          messageCount: 150,
          cost: 150 * 0.0079,
        },
        {
          date: "2024-08-04",
          companyName: "Company D",
          messageCount: 300,
          cost: 300 * 0.0079,
        },
      ],
      billingFields: [
        { key: "date", label: "Date", sortable: true },
        { key: "companyName", label: "Company Name", sortable: true },
        { key: "messageCount", label: "Messages Sent", sortable: true },
        { key: "cost", label: "Cost ($)", sortable: true },
      ],
      chartOptions: {
        chart: {
          id: "billing-chart",
        },
        xaxis: {
          type: "category", // Explicitly set this to treat x-axis values as categories
          categories: [],
        },
        title: {
          text: "Billing Over Time",
          align: "left",
        },
      },
      series: [
        {
          name: "Cost",
          data: [],
        },
      ],
    };
  },
  methods: {
    fetchBillingData() {
      // Static data already set, so just call renderGraph
      // this.renderGraph();
    },
    renderGraph() {
      const labels = this.billingData.map((item) => item.date);
      const data = this.billingData.map((item) => item.cost);

      this.chartOptions.xaxis.categories = labels;
      this.series = [
        {
          name: "Cost",
          data: data,
        },
      ];
    },
    downloadExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.billingData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Billing");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(data, "billing.xlsx");
    },
    saveAsPDF() {
      const doc = new jsPDF();
      doc.text("Billing Summary", 20, 10);
      doc.autoTable({
        head: [["Date", "Company Name", "Messages Sent", "Cost"]],
        body: this.billingData.map((item) => [
          item.date,
          item.companyName,
          item.messageCount,
          item.cost,
        ]),
      });
      doc.save("billing.pdf");
    },
    getResults() {
      axios
        .get("getBilling", {
          params: {
            company_id: this.companies.selected,
            dateRange: this.selectedDateRange,
          },
        })
        .then((response) => {
          const labels = response.data.map((item) => item.date);
          const data = response.data.map((item) => item.cost * this.billingSummary.costPerMessage);
          this.billingData = response.data;
          this.billingSummary.totalMessages = _.sum(
            response.data.map((item) => item.messageCount)
          );
          this.billingSummary.totalCost = this.billingSummary.totalMessages * this.billingSummary.costPerMessage
          this.chartOptions.xaxis.categories = labels;
          this.$refs.chart.updateOptions({
            xaxis: {
              categories: labels,
            },
          });
          this.$refs.chart.updateSeries([
            {
              name: "Cost",
              data: data,
            },
          ]);
          console.log(response.data);
        });
    },
    dropdownCompanies() {
      axios.get("/dropdowns/getAllCompanies").then((response) => {
        this.companies.selection = response.data;
      });
    },
    getBillingRate() {
      axios.get("getBillingRate").then((response) => {
        this.billingSummary.costPerMessage = Number (response.data.cost_per_segment)
      });
    },
  },
  mounted() {
    this.getBillingRate();
    this.dropdownCompanies();
    this.getResults();
 
  },
};
</script>

<style scoped>
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.apexcharts-canvas {
  width: 100% !important;
}
</style>
