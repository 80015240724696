<template>
  <div class="mt-4">
    <h2>Unassigned Conversations</h2>
    <b-row>
      <div class="form-group col-md-3">
        <b-form-select  v-if="user.user_role_id >= 3" v-model="companies.selected" :options="companies.selection" @input="getResults">
          <template #first>
              <b-form-select-option value="null" disabled>Select Company</b-form-select-option>
            </template>
        </b-form-select>
      </div>
      <div class="form-group col-md-9">
        <b-input-group size="md">
          <div class="col">
                <div class="d-flex">
                    <b-form-datepicker size="sm" v-model="selectedDateRange.start" class="mr-2" placeholder="Start Date" />
                    <b-form-datepicker size="sm" v-model="selectedDateRange.end"  class="mr-2" placeholder="End Date" />
                    <b-button class="mx-2" size="sm" @click="getResults">Apply</b-button>
                    <b-button size="sm" variant="success" @click="exportResult">Export</b-button>
                </div>
        </div>
        </b-input-group>
      </div>
    </b-row>
    <b-table striped hover :items="details" :fields="header"
      :busy="loading"
      :per-page="perPage"
      stacked="md"
      show-empty
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(logo)="data">
        <b-img width="50px" rounded :src="'data:image/png;base64,'+data.item.logo"  alt="User Logo"></b-img>
      </template>
      <template #cell(message_type)="data">
          <b-badge :style="{ backgroundColor: data.item.hex_code}"> {{ data.item.message_type }}</b-badge>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import 'sweetalert2/dist/sweetalert2.css';
import moment from 'moment';
const ExcelJS = require('exceljs');
import Swal from 'sweetalert2';
export default {
  props: ['user','userPermissions'],
  data() {
    return {
      details: [],
      header:[
        { key: 'company_name', label: 'Company' },
        { key: 'claimed_by', label: 'Claimer' },
        { key: 'claimed_date', label: 'Claimed Date' },
        { key: 'started_by', label: 'User' },
        { key: 'started_date', label: 'Started Date' },
        { key: "message_type", label: "Message Type" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true,
      companies:{
        selected:0,
        selection:[]
      },
      selectedDateRange: { start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") },
      selectedFilter:'4 Weeks'
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getResults();
    this.dropdownCompanies();
  },
  methods: {
      getResults(){
        axios.get('/getUnassignedConversations', {
            params: {
                company_id: this.companies.selected,
                dateRange: this.selectedDateRange,
                perPage: this.perPage,
                currentPage: this.currentPage,
            },
        })
        .then(response => {
            this.details = response.data
            this.loading = false
            this.totalRows = this.details.length
        })
        .catch(error => {
            console.error(error);
        });
      },
      dropdownCompanies(){
        axios.get('/dropdowns/getAllCompanies').then(response=>{
          this.companies.selection = response.data
        })
      },
      async exportResult(){
        if(this.details.length > 0){
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Data');

          worksheet.columns = [
            { key: 'company_name', header: 'Company' },
            { key: 'claimed_by', header: 'Claimer' },
            { key: 'claimed_date', header: 'Claimed Date' },
            { key: 'started_by', header: 'User' },
            { key: 'started_date', header: 'Started Date' },
          ];

          this.details.forEach(row => {
              worksheet.addRow(row);
          });

          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = 'Unassigned Conversations DTD-'+ moment().format("YYYY-MM-DD") + '.xlsx';
          downloadLink.click();
        }
        else{
          Swal.fire('No Details Found','','warning')
        }
      }
  },
  watch:{
   
  }
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
</style>