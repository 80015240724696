<template>
  <div class="mt-4">
    <b-row>
      <b-col>
        <h2>Directory</h2>
      </b-col>
      <b-col class="text-right">
        <b-button-group size="sm">
          <b-button
            v-for="option in user_classifications"
            :key="option.id"
            @click="applyFilter(option)"
            :variant="selectedFilter === option.id ? 'primary' : 'secondary'"
          >
            {{ option.description }}
          </b-button>
        </b-button-group>
        <!-- </b-card> -->
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col>
        <b-button
          v-if="hasPermission('broadcast message')"
          @click="showBroadcastModal"
          variant="warning"
          data-toggle="tooltip"
          data-placement="top"
          title="Broadcast"
        >
          <i class="fas fa-bullhorn"></i>
        </b-button>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-form-checkbox v-model="isMultiple" value="True" unchecked-value="False" switch>
          <b>{{ isMultiple == "True" ? "Multiple Select" : "Single Select" }}</b>
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search User Name"
            @input="getAllUsers"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-if="isMultiple == 'True'" class="my-2">
      <b-col class="text-right">
        <b-button @click="showGroupModal()" variant="info">
          <i class="fas fa-plus"></i><b> Group </b>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      hover
      :items="users"
      :fields="header"
      :busy="loading"
      :per-page="perPage"
      :select-mode="selectedMode"
      stacked="md"
      show-empty
      @row-clicked="onRowSelected"
      selectable
      @row-selected="onRowSelect"
      class="ma-4"
    >
      <template #table-busy>
        <div class="text-center text-primary mb-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(groups)="data">
        <div v-for="(group, index) in data.item.groups" :key="index">
          <b-badge pill variant="secondary">{{ group }}</b-badge>
        </div>
      </template>
      <template #cell(action)="data">
        <b-button
          v-if="hasPermission('send direct message')"
          variant="primary"
          @click="sendSms(data.item)"
          v-b-modal="'view_modal'"
          size="sm"
          ><i class="fas fa-paper-plane"></i
        ></b-button>
        <!-- <b-button variant="secondary" @click="editCompanySchedule(data.item.id)"  v-b-modal="'schedule_modal'" size="sm" ><i class="fas fa-clock"></i></b-button> -->
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <BModal
      v-if="addUpdateModalVisible"
      id="add_update_modal"
      ref="addUpdateModals"
      @hidden="resetModal"
      title="Contact Details"
      size="lg"
      hide-footer
    >
      <div class="container-fluid mt-2">
        <form
          @submit.prevent="addUpdateModal.button == 'Save' ? submitForm() : updateUser()"
          class="form-user"
        >
          <!-- User Information Section -->
          <!-- <h1 class="h3 mb-3 font-weight-normal text-center">User Information</h1> -->
          <div>
            <b-badge
              v-for="(group, index) in user.groups"
              :key="index"
              pill
              variant="secondary"
            >
              {{ group }}
              <b-button
                size="sm"
                @click="removeGroup(group)"
                style="border-radius: 100%; background: gray; margin-left: 2px"
              >
                <b-icon aria-hidden="true">X</b-icon>
              </b-button>
            </b-badge>
          </div>
          <div class="form-row">
            <!-- User Name -->
            <div class="form-group col-md-12">
              <label for="name">Name</label>
              <input
                type="text"
                v-model="user.name"
                id="name"
                class="form-control"
                required
              />
              <small id="nameHelp" class="form-text text-muted"
                >Enter the name of your user.</small
              >
            </div>

            <!-- Address -->
            <!-- <div class="form-group col-md-6">
              <label for="address">Address</label>
              <textarea v-model="user.address" id="address" class="form-control" required></textarea>
              <small id="addressHelp" class="form-text text-muted">Enter the address</small>
            </div> -->
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="contactNumber">Contact Number</label>
              <input
                type="text"
                v-model="user.contact_number"
                id="contactNumber"
                class="form-control"
                required
              />
              <small id="contactNumberHelp" class="form-text text-muted"
                >Enter the contact number of the user.</small
              >
            </div>

            <div class="form-group col-md-6">
              <label for="website">Email Address</label>
              <input type="text" v-model="user.email" id="website" class="form-control" />
              <small id="websiteHelp" class="form-text text-muted"
                >Enter the user email</small
              >
            </div>
          </div>
          <!-- Submit Button -->
          <button
            v-if="hasPermission('edit user')"
            class="btn btn-primary btn-block"
            type="submit"
          >
            {{ addUpdateModal.button }}
          </button>
        </form>
      </div>
    </BModal>

    <BModal
      v-if="groupModalVisible"
      id="group_modal"
      ref="groupModals"
      @hidden="resetModal"
      title="Group"
      size="lg"
      hide-footer
    >
      <div class="container-fluid mt-2">
        <form @submit.prevent="saveGroup()" class="form-user">
          <!-- User Information Section -->
          <!-- <h1 class="h3 mb-3 font-weight-normal text-center">User Information</h1> -->

          <div class="form-row">
            <!-- User Name -->
            <div class="form-group col-md-12">
              <label for="name">Group Name</label>
              <input
                type="text"
                v-model="group_name"
                id="name"
                class="form-control"
                required
              />
              <small id="nameHelp" class="form-text text-muted">Enter Group Name</small>
            </div>
          </div>
          <!-- Submit Button -->
          <button
            v-if="hasPermission('edit user')"
            class="btn btn-primary btn-block"
            type="submit"
          >
            Save
          </button>
        </form>
      </div>
    </BModal>
    <BModal
      id="broadcast_modal"
      ref="broadcastModals"
      @hidden="resetModal"
      title="Group"
      size="lg"
      hide-footer
    >
      <div class="container-fluid mt-2">
        <div class="filter-section">
          <b-form-group label="Filter Users">
            <div class="d-flex">
              <b-form-select
                v-model="selectedColumn"
                :options="columns"
                class="mr-2"
              ></b-form-select>
              <b-form-input
                v-model="searchText"
                placeholder="Enter search text"
                class="mr-2"
              ></b-form-input>
              <b-button @click="getAllUsersByGroupId" variant="primary">
                <i class="fas fa-search"></i>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <form @submit.prevent="sendAllSms()" class="form-user">
          <div class="form-row">
            <div class="form-group col-12">
              <label for="name">Groups</label>
              <b-form-select
                v-model="broadcast.group_id"
                :options="broadcast.group_list"
                value-field="id"
                text-field="name"
                @input="getAllUsersByGroupId"
              ></b-form-select>
              <small id="nameHelp" class="form-text text-muted">Select Group Name</small>
            </div>
            <hr />
            <div class="form-group col-6 ma-2">
              <div class="row pa-2">
                <div class="col-6">
                  <h5>Selection {{ "(" + filteredUserList.length + ")" }}</h5>
                </div>
                <div class="col-6 text-right">
                  <b-button size="sm" @click="selectAll()">Select All</b-button>
                </div>
              </div>
              <b-table
                small
                hover
                :items="filteredUserList"
                :fields="broadcast.user_header"
                :busy="broadcast.loading"
                stacked="md"
                show-empty
                @row-clicked="addToSelection"
                class="ma-4"
              >
                <template #table-busy>
                  <div class="text-center text-primary mb-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="form-group col-6 ma-2">
              <div class="row pa-2">
                <div class="col-6">
                  <h5>Selected {{ "(" + broadcast.user_list_selected.length + ")" }}</h5>
                </div>
                <div class="col-6 text-right">
                  <b-button size="sm" @click="deselectAll()">Deselect All</b-button>
                </div>
              </div>
              <b-table
                small
                hover
                :items="broadcast.user_list_selected"
                :fields="broadcast.user_header"
                :busy="broadcast.loading"
                stacked="md"
                show-empty
                @row-clicked="removeFromSelection"
                class="ma-4"
              >
                <template #table-busy>
                  <div class="text-center text-primary mb-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
          <button
            v-if="hasPermission('edit user')"
            class="btn btn-primary btn-block"
            type="submit"
          >
            Send SMS
          </button>
        </form>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
export default {
  props: ["userPermissions", "user_info"],
  data() {
    return {
      users: [],
      viewModalVisible: false,
      addUpdateModalVisible: false,
      groupModalVisible: false,
      broadcastModalVisible: false,
      deleteModalVisible: false,
      selectedUser: null,
      user: {
        id: "",
        name: "",
        company_name: "",
        contact_number: "",
        email: "",
        address: "",
        user_role_id: null,
        company_id: null,
        groups: [],
      },
      header: [
        { key: "name", label: "Name" },
        // { key: 'company_name', label: 'Company' },
        { key: "contact_number", label: "Contact #" },
        { key: "email", label: "Email" },
        { key: "role_description", label: "Role" },
        { key: "groups", label: "Group" },
        { key: "action", label: "Actions" },
      ],
      addUpdateModal: {
        title: "Add User",
        button: "Save",
        function: "submitForm",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 150, 200, { value: 250, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      loading: true,
      companies: {
        selected: 0,
        selection: [],
      },
      user_roles: {
        selected: 0,
        selection: [],
      },
      user_classifications: [],
      selectedFilter: 1,
      selected: [],
      selectedMode: "single",
      isMultiple: "False",
      group_name: null,
      broadcast: {
        group_id: null,
        group_list: [],
        user_list: [],
        user_header: [
          { key: "name", label: "Name" },
          { key: "contact_number", label: "Contact #" },
          { key: "role_description", label: "Role" },
        ],
        loading: false,
        user_list_selected: [],
      },
      selectedColumn: null,
      searchText: "",
      columns: [
        { value: null, text: "Select column" },
        { value: "name", text: "Name" },
        { value: "contact_number", text: "Contact Number" },
        { value: "email", text: "Email" },
        { value: "address", text: "Address" },
        { value: "city", text: "City" },
        { value: "state", text: "State" },
        { value: "zip_code", text: "Zip Code" },
      ],
    };
  },
  components: {
    BModal,
  },
  mounted() {
    this.getAllUsers();
    this.getUserClassificationList();
    this.getAllGroups();
  },
  methods: {
    getAllUsers() {
      this.users = [];
      this.loading = true;
      axios
        .get("/getAllUsersTable", {
          params: {
            perPage: this.perPage,
            currentPage: this.currentPage,
            filter: this.filter,
            origin: "address_book",
            user_classification_id: this.selectedFilter,
          },
        })
        .then((response) => {
          this.users = response.data.data;
          this.loading = false;
          this.totalRows = response.data.total;
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
    getAllUsersByGroupId() {
      this.broadcast.user_list = [];
      this.broadcast.loading = true;
      axios
        .get("/getAllUsersByGroupId", {
          params: {
            group_id: this.broadcast.group_id,
            column_name: this.selectedColumn,
            search: this.searchText,
          },
        })
        .then(async (response) => {
          this.broadcast.user_list = response.data;
          this.broadcast.loading = false;

          let selectedIds = this.broadcast.user_list_selected.map((user) => user.id);
          this.broadcast.user_list = this.broadcast.user_list.filter(
            (user) => !selectedIds.includes(user.id)
          );
          if(this.broadcast.group_id == null && this.selectedColumn == null){
            this.broadcast.user_list = []
          }
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
    editModal(id) {
      this.addUpdateModalVisible = true;
      this.addUpdateModal.title = "Edit User";
      this.addUpdateModal.button = "Update";
      this.addUpdateModal.function = "updateUser";
      this.selectedUser = this.users.find((user) => user.id === id);
      this.user = this.selectedUser;
    },
    updateUser() {
      const formData = new FormData();
      for (const key in this.selectedUser) {
        formData.append(key, this.selectedUser[key]);
      }
      axios
        .put(`/users/${this.selectedUser.id}`, this.selectedUser)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          this.addUpdateModalVisible = false;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.error || "Failed to update user",
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false;
      this.selectedUser = null;
      this.groupModalVisible = false;
      this.broadcastModalVisible = false;
    },
    async onRowSelected(item) {
      if (this.isMultiple != "True") {
        await this.editModal(item.id);
        const modal = this.$refs.addUpdateModals;
        if (modal) {
          // You can use either show or toggle based on your requirements

          modal.show();
          // Alternatively, you can use modal.toggle();
        }
      }
    },
    onRowSelect(items) {
      this.selected = items;
    },
    hasPermission(permissionName) {
      return this.userPermissions.some(
        (permission) => permission.name === permissionName
      );
    },
    getUserClassificationList() {
      axios
        .get("/getUserClassificationList")
        .then((response) => {
          this.user_classifications = response.data;
          console.log(this.user_classifications);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    },
    async applyFilter(option) {
      this.selectedFilter = option.id;
      await this.getAllUsers();
    },
    async sendSms(recipient) {
      let { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Are you sure you want to Start a conversation?",
        inputValue: !!this.user_info.preset_message ? this.user_info.preset_message : "",
        inputPlaceholder: "Type your message here...",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          // encodeURIComponent(encryptedEmail)
          const currentTimestamp = new Date().getTime();
          let encodedEmail = this.$encryptEmail(recipient.email);
          let encodedFrom = this.$encryptEmail(this.user_info.email);
          let encodedTs = this.$encryptEmail(currentTimestamp);
          const origin = window.location.origin;
          const url = `${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}'`;
          axios
            .post(`send-sms-to-client`, {
              message_url: url,
              body: result.value,
              recipient_email: recipient.email,
              sender_email: this.user_info.email,
            })
            .then((response) => {
              Swal.fire("Message sent successfully", "", "success");
            });
        }
      });

      // Swal.fire({
      //     title: 'Are you sure you want to Start a conversation?',
      //     text: "",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Confirm',
      //     reverseButtons: true
      //     }).then((result) => {
      //     if (result.isConfirmed) {
      //         // encodeURIComponent(encryptedEmail)
      //         const currentTimestamp = new Date().getTime();
      //         let encodedEmail = this.$encryptEmail(this.recipient.email)
      //         let encodedFrom = this.$encryptEmail(this.user.email)
      //         let encodedTs = this.$encryptEmail(currentTimestamp)
      //         const origin = window.location.origin;
      //         const url = `'${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}''`;
      //         console.log(url)
      //         axios.post(`send-sms-to-client`,
      //               {  message_url:url}
      //         ).then(response=>{
      //             alert('message sent')
      //         })
      //     }
      // })
    },
    async showGroupModal() {
      await this.showGroupButton();
      const modal = this.$refs.groupModals;
      if (modal) {
        // You can use either show or toggle based on your requirements

        modal.show();
        // Alternatively, you can use modal.toggle();
      }
    },
    async showBroadcastModal() {
      this.broadcast.user_list_selected = [];
      this.broadcast.user_list = [];
      this.broadcast.group_id = null
      this.selectedColumn = null
      this.searchText = null
      const modal = this.$refs.broadcastModals;
      if (modal) {
        // You can use either show or toggle based on your requirements

        modal.show();
        // Alternatively, you can use modal.toggle();
      }
    },
    showGroupButton() {
      this.groupModalVisible = true;
    },
    saveGroup() {
      let params = {
        users: this.selected.map((e) => {
          return e.id;
        }),
        group_name: this.group_name,
      };

      axios.post("/saveGroupName", params).then((response) => {
        Swal.fire(response.data.msg, "", response.data.icon);
        if (response.data.icon == "success") {
          this.getAllUsers();
          this.groupModalVisible = false;
        }
      });
    },
    removeGroup(group_name) {
      axios
        .post("/removeGroup", {
          group_name: group_name,
          user_id: this.user.id,
        })
        .then((response) => {
          Swal.fire(response.data.msg, "", response.data.icon);
          this.user.groups = this.user.groups.filter((group) => group !== group_name);
          this.getAllUsers();
        });
    },
    getAllGroups() {
      axios.get("/getAllGroups").then((response) => {
        console.log(response.data.unshift({id:null,name:'Select group'}))
        this.broadcast.group_list = response.data;
        
      });
    },
    removeFromSelection(item) {
      this.broadcast.user_list_selected = _.remove(
        this.broadcast.user_list_selected,
        function (n) {
          return n != item;
        }
      );
    },
    addToSelection(item) {
      this.broadcast.user_list_selected.push(item);
    },
    selectAll() {
      if (this.filteredUserList.length > 0) {
        this.broadcast.user_list.forEach((item) => {
          this.broadcast.user_list_selected.push(item);
        });
      }
    },
    deselectAll() {
      this.getAllUsersByGroupId();
      this.broadcast.user_list_selected = [];
    },
    async sendAllSms() {
      if (this.broadcast.user_list_selected.length < 1) {
        Swal.fire("Please select User", "", "warning");
        return;
      }

      const modal = this.$refs.broadcastModals;
      // if (modal) {
      // You can use either show or toggle based on your requirements

      modal.hide();
      // Alternatively, you can use modal.toggle();
      // }
      let { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Please type your broadcast message",
        inputValue: !!this.user_info.preset_message ? this.user_info.preset_message : "",
        inputPlaceholder: "Type your message here...",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          // encodeURIComponent(encryptedEmail)
          const currentTimestamp = new Date().getTime();
          // let encodedEmail = this.$encryptEmail(recipient.email);
          // let encodedFrom = this.$encryptEmail(this.user_info.email);
          // let encodedTs = this.$encryptEmail(currentTimestamp);
          // const origin = window.location.origin;
          // const url = `${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}'`;
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, send it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          }).then((res) => {
            if (res.isConfirmed) {
              axios
                .post(`send-sms-to-client-broadcast`, {
                  // message_url: '',
                  body: result.value,
                  // recipient_email: recipient.email,
                  // sender_email: this.user_info.email,
                  user_ids: this.broadcast.user_list_selected.map((e) => {
                    return e.id;
                  }),
                })
                .then((response) => {
                  Swal.fire("Message sent successfully", "", "success");
                });
            } else {
              return false;
            }
          });
        }
      });

      // Swal.fire({
      //     title: 'Are you sure you want to Start a conversation?',
      //     text: "",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'Confirm',
      //     reverseButtons: true
      //     }).then((result) => {
      //     if (result.isConfirmed) {
      //         // encodeURIComponent(encryptedEmail)
      //         const currentTimestamp = new Date().getTime();
      //         let encodedEmail = this.$encryptEmail(this.recipient.email)
      //         let encodedFrom = this.$encryptEmail(this.user.email)
      //         let encodedTs = this.$encryptEmail(currentTimestamp)
      //         const origin = window.location.origin;
      //         const url = `'${origin}/chat?email=${encodedEmail}&from=${encodedFrom}&ts=${encodedTs}''`;
      //         console.log(url)
      //         axios.post(`send-sms-to-client`,
      //               {  message_url:url}
      //         ).then(response=>{
      //             alert('message sent')
      //         })
      //     }
      // })
    },
  },
  computed: {
    filteredUserList() {
      return this.broadcast.user_list.filter(
        (user) => !this.broadcast.user_list_selected.includes(user)
      );
    },
  },
  watch: {
    currentPage(val) {
      this.getAllUsers();
    },
    isMultiple(val) {
      if (val == "True") {
        this.selectedMode = "multi";
      } else {
        this.selectedMode = "single";
      }
    },
    "broadcast.user_list": {
      handler(newValue, oldValue) {
        // if(newValue != oldValue){
        //   this.broadcast.user_list = this.broadcast.user_list.filter(
        //     user => !this.broadcast.user_list_selected.some(selected => selected.id === user.id)
        //   );
        // }
        // Perform any actions you need when user_list changes
      },
      deep: true, // This makes the watcher work for nested properties
    },
  },
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
</style>
