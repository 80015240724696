<template>
  <div class="mt-4">
    <h2>Message Types Details</h2>
    <b-row class="my-2">
      <b-col>
        <b-button  v-if="hasPermission('create message type')" @click="addMessageTypes()" v-b-modal="'add_update_modal'" variant="primary" size="sm">+ ADD</b-button>
      </b-col>
      <b-col>
        
      </b-col>
    </b-row>
    <b-table hover :items="items" :fields="header"
      :per-page="perPage"
      stacked="md"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(hex_code)="data">
        <b-progress class="mb-3" :style='"background-color:"+data.item.hex_code+""'></b-progress>
      </template>
      <template #cell(action)="data">
          <!-- <b-button v-if="hasPermission('view user')" variant="warning" @click="viewModal(data.item.id,'',$event.target)" v-b-modal="'view_modal'" size="sm"><i class="fas fa-eye"></i></b-button> -->
          <b-button v-if="hasPermission('edit message type')" variant="primary" @click="editModal(data.item.id)" v-b-modal="'add_update_modal'" size="sm"><i class="fas fa-edit"></i></b-button>
          <b-button v-if="hasPermission('delete message type')" variant="danger" @click="deleteModal(data.item.id)"  v-b-modal="'delete_modal'" size="sm" ><i class="fas fa-user-times"></i></b-button>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  
    <!-- Delete Modal -->
    <BModal v-if="deleteModalVisible" title="Delete Message Type" id="delete_modal" @hidden="resetModal" hide-footer>
      <p>Are you sure you want to delete selected Message Type??</p>
      <b-button variant="danger" @click="confirmDelete">Yes, Delete</b-button>
    </BModal>

    <BModal  v-if="addUpdateModalVisible" id="add_update_modal" @hidden="resetModal" :title="addUpdateModal.title" size="md" hide-footer> 
      <div class="container-fluid mt-2">
        <form @submit.prevent="addUpdateModal.button == 'Save' ? submitForm() : updateForm()" class="form-user">
          <!-- Message Type Information Section -->
          <h1 class="h3 mb-3 font-weight-normal text-center">Message Type Information</h1>

          <div class="form-row">
            <!-- Message Type Code -->
            
            <!-- Message Type Name -->
            <div class="form-group col-6">
              <label for="name">Message Type</label>
              <input type="text" v-model="selected_item.description" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Message Type.</small>
            </div>  

            <div class="form-group col-4">
              <label for="name">Color</label>
              <b-dropdown id="dropdown" :text="selected_option_color.hex_code" split split-variant="outlined-primary">
                <b-dropdown-item
                  v-for="option in message_type_color.selection"
                  :key="option.id"
                  @click="assignOptionColor(option)"
                >
                  <div class="option-item">
                    <span class="color-box" :style="{ backgroundColor: option.hex_code }"></span>
                    {{ option.hex_code }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="form-group col-2">
              <label for="name">Preview</label>
              <span class="color-box-a" :style="{ backgroundColor: selected_option_color.hex_code }"></span>
              </div>
          </div>

          <!-- Submit Button -->
          <button class="btn btn-primary btn-block" type="submit">{{addUpdateModal.button}}</button>
        </form>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  props: ['userPermissions'],
  data() {
    return {
      items: [],
      viewModalVisible: false,
      addUpdateModalVisible: false,
      deleteModalVisible: false,
      selectedItem: null,
      selected_item: {
        id: '',
        description: '',
        message_type_color_id: '',
        code:'',
        hex_code:'Select color'
      },
      header:[
        { key: 'description', label: 'Description' },
        { key: 'hex_code', label: 'Color' },
        { key: 'action', label: 'Actions' },
      ],
      addUpdateModal:{
        title:'Add Message Type',
        button:'Save',
        function:'submitForm'
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true,
      message_type_color:{
        selection:[],
      },
      selected_option_color:{
        id:null,
        description:'Select a Color',
        hex_code:null
      }
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getTableDetails();
    this.getAllMessageTypeColors();
    this.loading = false
  },
  methods: {
    viewModal(id, index, button) {
      this.addUpdateModal.title = 'Add Message Type'
      this.addUpdateModal.button = 'Save'
      this.selectedItem = this.items.find(user => user.id === id);
      this.viewModalVisible = true;
    },
    editModal(id) {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Edit Message Type'
      this.addUpdateModal.button = 'Update'
      this.addUpdateModal.function = 'updateForm'
      this.selectedItem = this.items.find(user => user.id === id);
      this.selected_item = this.selectedItem
      this.getAllMessageTypeColors();

    },
    addMessageTypes() {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Add Message Type'
      this.addUpdateModal.button = 'Save'
      this.addUpdateModal.function = 'submitForm'
      this.selected_item = { ...this.$options.data().user };
      this.getAllMessageTypeColors();
    },
    updateForm() {
      const formData = new FormData();
      for (const key in this.selectedItem) {
        formData.append(key, this.selectedItem[key]);
      }
      axios.put(`/message_types/${this.selectedItem.id}`, this.selectedItem)
      .then(response => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.addUpdateModalVisible = false
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update message types',
          });
      });
      
    },
    deleteModal(id) {
      this.selectedItem = this.items.find(user => user.id === id);
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      this.deleteModalVisible = false;
      const id = this.selectedItem.id;
      axios.post(`message_types/delete-confirm/${id}`)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          this.getTableDetails();
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to delete message types',
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false
      this.selectedItem = null;
      this.selected_option_color = { ...this.$options.data().selected_option_color };
    },
    submitForm() {
        const formData = new FormData();
        for (const key in this.selected_item) {
          formData.append(key, this.selected_item[key]);
        }
  
        axios.post('/message_types', formData)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            this.getTableDetails()
            this.addUpdateModalVisible = false
            this.resetModal();
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.error || 'Failed to save message types',
            });
          });
      },
      hasPermission(permissionName) {
      return true // make true for a while
          return this.userPermissions.some(permission => permission.name === permissionName);
      },
      getTableDetails() {
        axios.get('/getMessageTypesList')
          .then(response => {
            this.items = response.data
            this.loading = false
            this.perPage = response.data.length
          })
          .catch(error => {
            console.error('Error fetching message_types:', error);
          });
      },
      getAllMessageTypeColors() {
        axios.get('/getAllMessageTypeColors')
          .then(response => {
            this.message_type_color.selection = response.data
          })
          .catch(error => {
            console.error('Error fetching message_type_colors:', error);
          });
      },
      assignOptionColor(item){
        this.selected_option_color.hex_code = item.hex_code
        this.selected_option_color.id = item.id
        this.selected_option_color.description = item.description
        this.selected_item.message_type_color_id = item.id
      }
  },
  watch:{
    currentPage(val){
      this.getTableDetails()
    }
  },
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
.color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
}
.color-box-a {
  display: inline-block;
  width: 35px;
  height:35px;
  margin-right: 10px;
  border: 1px solid #ccc;
}
</style>