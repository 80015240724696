<template>
  <div class="mt-4">
    <h2>Resolved Conversations</h2>
    <b-row>
      <div class="form-group col-md-3">
        <b-form-select
          v-if="user.user_role_id >= 3"
          v-model="companies.selected"
          :options="companies.selection"
          @input="getResults"
        >
          <template #first>
            <b-form-select-option value="null" disabled
              >Select Company</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
      <div class="form-group col-md-9">
        <b-input-group size="md">
          <div class="col">
            <div class="d-flex">
              <b-form-datepicker
                size="sm"
                v-model="selectedDateRange.start"
                class="mr-2"
                placeholder="Start Date"
              />
              <b-form-datepicker
                size="sm"
                v-model="selectedDateRange.end"
                class="mr-2"
                placeholder="End Date"
              />
              <b-button class="mx-2" size="sm" @click="getResults">Apply</b-button>
              <b-button size="sm" variant="success" @click="exportResult(null)"
                >Export</b-button
              >
            </div>
          </div>
        </b-input-group>
      </div>
    </b-row>
    <b-table
      striped
      hover
      :items="details"
      :fields="header"
      :busy="loading"
      :per-page="perPage"
      stacked="md"
      show-empty
      @row-clicked="onRowSelected"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(logo)="data">
        <b-img
          width="50px"
          rounded
          :src="'data:image/png;base64,' + data.item.logo"
          alt="User Logo"
        ></b-img>
      </template>
      <template #cell(message_type)="data">
          <b-badge :style="{ backgroundColor: data.item.hex_code}"> {{ data.item.message_type }}</b-badge>
      </template>
      <template #cell(action)="data">
          <b-button data-toggle="tooltip" data-placement="top" title="Download" variant="success" size="sm" @click="exportResult(data.item)"><i class="fas fa-download"></i></b-button>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <BModal
      ref="showConversations"
      size="lg"
      hide-footer
      title="Conversation"
      @hidden="closeModal"
      centered
    >
      <div class="col-12" style="max-height: 63vh">
        <div class="card transparent-card" style="max-height: 63vh">
          <div class="card-body transparent-card p-0">
            <ul
              class="list-unstyled"
              style="max-height: 100%; overflow-y: scroll"
              v-chat-scroll="{ always: false, smooth: true }"
            >
              <li v-for="message in allMessage">
                <b>Session ID: </b>{{ sessionTextId }}
                <p></p>
                <hr />
                <p class="text-center">{{ formatDateTime(message.started_date) }}</p>
                <p
                  v-if="!!message.claimed_by"
                  class="text-center claimed-box p-0 m-4"
                  role="alert"
                >
                  Claimed by: {{ message.claimed_by_name }}
                </p>
                <p
                  v-for="chatMessage in message.message"
                  :class="
                    chatMessage.user_id != user.id ? 'px-2 text-left' : 'px -2 text-right'
                  "
                >
                  <strong>{{ chatMessage.name }}</strong>
                  <br />
                  <img
                    v-if="chatMessage.user_id != user.id"
                    :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`"
                    alt="avatar"
                    class="rounded-circle shadow-1-strong"
                    width="35"
                  />

                  <span
                    v-if="checkIfHasString(chatMessage.message)"
                    :class="
                      chatMessage.user_id != user.id
                        ? 'bg-light message-box-other px-2 py-1'
                        : 'bg-light message-box px-2 py-1'
                    "
                  >
                    {{ chatMessage.message }}</span
                  >
                  <span v-else
                    ><img
                      width="50%"
                      :src="chatMessage.message"
                      alt="Image"
                      @click="openModal(chatMessage.message)"
                  /></span>

                  <img
                    v-if="chatMessage.user_id == user.id"
                    :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`"
                    alt="avatar"
                    class="rounded-circle shadow-1-strong"
                    width="35"
                  />
                </p>
                <p
                  v-if="!!message.resolved_by"
                  class="text-center p-0 m-4 resolved-box"
                  role="alert"
                >
                  Resolved by: {{ message.resolved_by_name }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
const ExcelJS = require("exceljs");
import Swal from "sweetalert2";
export default {
  props: ["user", "userPermissions"],
  data() {
    return {
      details: [],
      header: [
        { key: "session_id", label: "Session ID" },
        { key: "company_name", label: "Company" },
        { key: "claimed_by", label: "Claimer" },
        { key: "claimed_date", label: "Claimed Date" },
        { key: "started_by", label: "User" },
        { key: "started_date", label: "Started Date" },
        { key: "resolved_by", label: "Resolved By" },
        { key: "resolved_date", label: "Resolved Date" },
        { key: "message_type", label: "Message Type" },
        { key: "action", label: "Action" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      loading: true,
      companies: {
        selected: 0,
        selection: [],
      },
      selectedDateRange: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      selectedFilter: "4 Weeks",
      allMessage: [],
      sessionId: null,
      sessionTextId: null,
    };
  },
  components: {
    BModal,
  },
  mounted() {
    this.getResults();
    this.dropdownCompanies();
  },
  methods: {
    getResults() {
      axios
        .get("/getResolvedConversations", {
          params: {
            company_id: this.companies.selected,
            dateRange: this.selectedDateRange,
            perPage: this.perPage,
            currentPage: this.currentPage,
          },
        })
        .then((response) => {
          this.details = response.data;
          this.loading = false;
          this.totalRows = this.details.length
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dropdownCompanies() {
      axios.get("/dropdowns/getAllCompanies").then((response) => {
        this.companies.selection = response.data;
      });
    },
    async exportResult(session = null) {
      if (this.details.length > 0) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Data");
        let data = this.details
        let session_name = ''
        worksheet.columns = [
          { key: "session_id", header: "Session ID" },
          { key: "company_name", header: "Company" },
          { key: "claimed_by", header: "Claimer" },
          { key: "claimed_date", header: "Claimed Date" },
          { key: "started_by", header: "User" },
          { key: "started_date", header: "Started Date" },
          { key: "resolved_by", header: "Resolved By" },
          { key: "resolved_date", header: "Resolved Date" },
          { key: "message_type", header: "Message Type" },
          { key: "name", header: "Name" },
          { key: "message", header: "Message" },
          { key: "created_at", header: "Created At" },
        ];
        if(!!session){
           data = _.filter(this.details, { 'id': session.id });
           session_name = ' - '+session.session_id
        }
        data.forEach((row) => {
          worksheet.addRow({
            session_id: row.session_id,
            company_name: row.company_name,
            claimed_by: row.claimed_by,
            claimed_date: row.claimed_date,
            started_by: row.started_by,
            started_date: row.started_date,
            resolved_by: row.resolved_by,
            resolved_date: row.resolved_date,
            message_type: row.message_type,
            name: "", // Empty to differentiate main rows
            message: "", // Empty to differentiate main rows
            created_at: "", // Empty to differentiate main rows
          });

          // Add messages for each row under the same session_id
          row.message.forEach((msg) => {
            worksheet.addRow({
              session_id: row.session_id, // Same session ID to group messages
              company_name: "", // Empty to differentiate message rows
              claimed_by: "", // Empty to differentiate message rows
              claimed_date: "", // Empty to differentiate message rows
              started_by: "", // Empty to differentiate message rows
              started_date: "", // Empty to differentiate message rows
              resolved_by: "", // Empty to differentiate message rows
              resolved_date: "", // Empty to differentiate message rows
              message_type: row.message_type,
              name: msg.name, // Adding name from the message object
              message: msg.message, // Adding message from the message object
              created_at: msg.created_at, // Adding created_at from the message object
            });
          });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download =
          "Resolved Conversations DTD-" + moment().format("YYYY-MM-DD") + session_name + ".xlsx";
        downloadLink.click();
      } else {
        Swal.fire("No Details Found", "", "warning");
      }
    },
    async onRowSelected(item) {
      await this.showConversations(item.id, item.session_id);
      const modal = this.$refs.showConversations;
      if (modal) {
        // You can use either show or toggle based on your requirements

        modal.show();
        // Alternatively, you can use modal.toggle();
      }
    },
    async showConversations(session_id, session_text_id) {
      this.sessionId = session_id;
      this.sessionTextId = session_text_id;
      await this.getMessages();
    },
    closeModal() {
      const modal = this.$refs.showConversations;
      if (modal) {
        // You can use either show or toggle based on your requirements

        modal.hide();
        // Alternatively, you can use modal.toggle();
      }
    },
    async getMessages() {
      let params = {
        params: {
          selected_user: null,
          session_id: this.sessionId,
        },
      };
      await axios.get("/messages", params).then(async (response) => {
        this.allMessage = response.data;
      });
    },
    formatDateTime(date) {
      return this.$formatDate(date);
    },
    checkIfHasString(message) {
      if (message.includes("data:")) {
        return false;
      }
      return true;
    },
  },
  watch: {},
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
</style>
