<template>
  <div class="mt-4">
    <h2>Department Details</h2>
    <b-row class="my-2">
      <b-col>
        <b-button  v-if="hasPermission('create department')" @click="addUser()" v-b-modal="'add_update_modal'" variant="primary" size="sm">+ ADD</b-button>
      </b-col>
      <b-col>
        
      </b-col>
    </b-row>
    <b-table striped hover :items="items" :fields="header"
      :per-page="perPage"
      stacked="md"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(action)="data">
          <!-- <b-button v-if="hasPermission('view user')" variant="warning" @click="viewModal(data.item.id,'',$event.target)" v-b-modal="'view_modal'" size="sm"><i class="fas fa-eye"></i></b-button> -->
          <b-button v-if="hasPermission('edit department')" variant="primary" @click="editModal(data.item.id)" v-b-modal="'add_update_modal'" size="sm"><i class="fas fa-edit"></i></b-button>
          <b-button v-if="hasPermission('delete department')" variant="danger" @click="deleteModal(data.item.id)"  v-b-modal="'delete_modal'" size="sm" ><i class="fas fa-user-times"></i></b-button>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  
    <!-- Delete Modal -->
    <BModal v-if="deleteModalVisible" title="Delete Department" id="delete_modal" @hidden="resetModal" hide-footer>
      <p>Are you sure you want to delete selected Department??</p>
      <b-button variant="danger" @click="confirmDelete">Yes, Delete</b-button>
    </BModal>

    <BModal  v-if="addUpdateModalVisible" id="add_update_modal" @hidden="resetModal" :title="addUpdateModal.title" size="md" hide-footer> 
      <div class="container-fluid mt-2">
        <form @submit.prevent="addUpdateModal.button == 'Save' ? submitForm() : updateForm()" class="form-user">
          <!-- Department Information Section -->
          <h1 class="h3 mb-3 font-weight-normal text-center">Department Information</h1>

          <div class="form-row">
            <!-- Department Code -->
            <div class="form-group col-4">
              <label for="name">Code</label>
              <input type="text" v-model="selected_item.code" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Code.</small>
            </div>  
            <!-- Department Name -->
            <div class="form-group col-8">
              <label for="name">Department</label>
              <input type="text" v-model="selected_item.description" id="name" class="form-control" required>
              <small id="nameHelp" class="form-text text-muted">Enter Department.</small>
            </div>  
          </div>

          <!-- Submit Button -->
          <button class="btn btn-primary btn-block" type="submit">{{addUpdateModal.button}}</button>
        </form>
      </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  props: ['userPermissions'],
  data() {
    return {
      items: [],
      viewModalVisible: false,
      addUpdateModalVisible: false,
      deleteModalVisible: false,
      selectedItem: null,
      selected_item: {
        id: '',
        description: '',
        code:''
      },
      header:[
        { key: 'code', label: 'Code' },
        { key: 'description', label: 'Description' },
        { key: 'action', label: 'Actions' },
      ],
      addUpdateModal:{
        title:'Add Department',
        button:'Save',
        function:'submitForm'
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true,
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getTableDetails();
    this.loading = false
  },
  methods: {
    viewModal(id, index, button) {
      this.addUpdateModal.title = 'Add Department'
      this.addUpdateModal.button = 'Save'
      this.selectedItem = this.items.find(user => user.id === id);
      this.viewModalVisible = true;
    },
    editModal(id) {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Edit Department'
      this.addUpdateModal.button = 'Update'
      this.addUpdateModal.function = 'updateForm'
      this.selectedItem = this.items.find(user => user.id === id);
      this.selected_item = this.selectedItem

    },
    addUser() {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Add Department'
      this.addUpdateModal.button = 'Save'
      this.addUpdateModal.function = 'submitForm'
      this.selected_item = { ...this.$options.data().user };
    },
    updateForm() {
      const formData = new FormData();
      for (const key in this.selectedItem) {
        formData.append(key, this.selectedItem[key]);
      }
      axios.put(`/departments/${this.selectedItem.id}`, this.selectedItem)
      .then(response => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.addUpdateModalVisible = false
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update classification',
          });
      });
      
    },
    deleteModal(id) {
      this.selectedItem = this.items.find(user => user.id === id);
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      this.deleteModalVisible = false;
      const id = this.selectedItem.id;
      axios.post(`departments/delete-confirm/${id}`)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          this.getTableDetails();
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to delete classification',
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false
      this.selectedItem = null;
    },
    submitForm() {
        const formData = new FormData();
        for (const key in this.selected_item) {
          formData.append(key, this.selected_item[key]);
        }
  
        axios.post('/departments', formData)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            this.getTableDetails()
            this.addUpdateModalVisible = false
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.error || 'Failed to save classification',
            });
          });
      },
      hasPermission(permissionName) {
      return true // make true for a while
          return this.userPermissions.some(permission => permission.name === permissionName);
      },
      getTableDetails() {
        axios.get('/getDepartmentList')
          .then(response => {
            this.items = response.data
            this.loading = false
            this.perPage = response.data.length
          })
          .catch(error => {
            console.error('Error fetching departments:', error);
          });
      },
  },
  watch:{
    currentPage(val){
      this.getTableDetails()
    }
  }
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}
</style>