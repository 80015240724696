<template>
  <div class="mt-4">
    <h2>Profile</h2>
    <b-row class="my-2">
      <b-col>
        <div>
          <b-card bg-variant="light">
            <b-form-group
              label-cols-lg="3"
              label="User Info"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
              :disabled="fields.isDisabled"
            >
              <b-form-group
                label="Name:"
                label-for="user-name"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-input v-model="user.name"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Company:"
                label-for="nested-city"
                label-cols-sm="3"
                label-align-sm="right"
                disabled
              >
                <b-form-input v-model="user.company_name" disabled></b-form-input>
              </b-form-group>

              <b-form-group
                label="Contact Number:"
                label-for="nested-state"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-input v-model="user.contact_number"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Email:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-input v-model="user.email"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Address:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-input v-model="user.address"></b-form-input>
              </b-form-group>

              <b-form-group
                label="Preset Message:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-textarea v-model="user.preset_message"></b-form-textarea>
              </b-form-group>

              <b-form-group
                label="Receive Email Notification:"
                label-for="nested-country"
                label-cols-sm="3"
                label-align-sm="right"
              >
                <b-form-checkbox
                  value="On"
                  unchecked-value="Off"
                  v-model="user.email_notification"
                  switch
                  size="lg"
                  name="switch"
                  id="switch-1"
                >
                  {{ user.email_notification }}
                </b-form-checkbox>
              </b-form-group>
            </b-form-group>
            <b-row>
              <b-col class="text-right">
                <b-button
                  v-if="button.isShowCancel"
                  variant="danger"
                  @click="clickActionButton('cancel')"
                  >Cancel</b-button
                >
                <b-button
                  :variant="button.variant"
                  @click="clickActionButton(button.action)"
                  >{{ button.action }}</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
export default {
  props: ["use"],
  data() {
    return {
      user: {
        id: "",
        name: "",
        company_name: "",
        contact_number: "",
        email: "",
        address: "",
        user_role_id: null,
        company_id: null,
        preset_message: "",
        email_notification: "On",
      },
      button: {
        action: "Edit",
        variant: "secondary",
      },
      fields: {
        isDisabled: true,
      },
    };
  },
  components: {},
  async mounted() {
    this.user = this.use;
    console.log(this.user);
  },
  methods: {
    clickActionButton(action) {
      if (action == "Edit") {
        this.button.action = "Save";
        this.button.variant = "primary";
        this.fields.isDisabled = false;
        this.button.isShowCancel = true;
      } else if (action == "Save") {
        this.button.action = "Edit";
        this.button.variant = "secondary";
        this.fields.isDisabled = true;
        this.button.isShowCancel = false;

        if (!!this.user.name && !!this.user.address && !!this.user.preset_message) {
          axios
            .post("user-profile/save", {
              user: this.user,
            })
            .then((response) => {
              Swal.fire("Saved Successfully", "", "success");
            });
        }
      } else {
        this.fields.isDisabled = true;
        this.button.action = "Edit";
        this.button.variant = "secondary";
        this.button.isShowCancel = false;
      }
    },
  },
  watch: {},
};
</script>
<style scoped></style>
