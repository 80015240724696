<template>
  <div class="mt-4">
    <h2>Users Details</h2>
    <b-row class="my-2">
      <b-col>
        <b-button  v-if="hasPermission('create user')" @click="addUser()" v-b-modal="'add_update_modal'" variant="primary" size="sm">+ ADD</b-button>
      </b-col>
      <b-col>
        <b-button class="float-right" variant="success" @click="showModal = true">Upload</b-button>
      </b-col>
    </b-row>
    <b-modal v-model="showModal" title="Upload Excel File">
      Instruction: 
      <ol>
        <li>Download the template excel file</li>
        <li>Open the downloaded Excel File and Fill out the details</li>
        <li>Make sure the classficiation, role, department and other details are correct</li>
        <li>Save the file and upload in this form</li>
      </ol>

      <b-button variant="link" href="/download-template" target="_blank" class="mt-3 text-right">
          <i class="fas fa-file-excel" style="color:green"></i> Download Template
      </b-button>
      <b-form @submit.prevent="uploadFile">
        <b-form-file v-model="file" accept=".xlsx,.xls" required></b-form-file>
        <b-button type="submit" variant="primary" class="mt-3">Upload</b-button>
      </b-form>
      <div v-if="message" :class="{'alert-success': success, 'alert-danger': !success}" class="alert mt-3">
        {{ message }}
      </div>
      
    </b-modal>
    <b-row>
      <!-- <div>
        <b-form @submit.prevent="uploadFile">
          <b-form-file v-model="file" accept=".xlsx,.xls" required></b-form-file>
          <b-button type="submit" variant="primary">Upload</b-button>
        </b-form>
        <div v-if="message" :class="{'alert-success': success, 'alert-danger': !success}" class="alert">
          {{ message }}
        </div>
      </div> -->
      <div class="form-group col-md-3">
        <b-form-select v-model="filters.company_id" :options="companies.selection" @input="getAllUsers">
          <template #first>
              <b-form-select-option value="null" disabled>Select Company</b-form-select-option>
            </template>
        </b-form-select>
      </div>
      <div class="form-group col-md-3">
        <!-- <b-form-select v-model="user.user_role_id" :options="user_roles.selection">
        <template #first>
            <b-form-select-option value="null" disabled>-- Company --</b-form-select-option>
          </template>
        </b-form-select> -->
      </div>
      <div class="form-group col-md-6">
        <b-input-group size="md">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search User Name"
            @input="getAllUsers"
          ></b-form-input>

          <b-input-group-append>
            <b-button  @click="clearFilters">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-row>
    <b-table striped hover :items="users" :fields="header"
      :busy="loading"
      :per-page="perPage"
      stacked="md"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(logo)="data">
        <b-img width="50px" rounded :src="'data:image/png;base64,'+data.item.logo"  alt="User Logo"></b-img>
      </template>
      <template #cell(action)="data">
          <b-button data-toggle="tooltip" data-placement="top" title="View" v-if="hasPermission('view user')" variant="warning" @click="viewModal(data.item.id,'',$event.target)" v-b-modal="'view_modal'" size="sm"><i class="fas fa-eye"></i></b-button>
          <b-button data-toggle="tooltip" data-placement="top" title="Edit" v-if="hasPermission('edit user')" variant="primary" @click="editModal(data.item.id)" v-b-modal="'add_update_modal'" size="sm"><i class="fas fa-edit"></i></b-button>
          <b-button data-toggle="tooltip" data-placement="top" title="Inactive" v-if="hasPermission('delete user')" variant="danger" @click="deleteModal(data.item.id)"  v-b-modal="'delete_modal'" size="sm" ><i class="fas fa-user-times"></i></b-button>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  
    <!-- View Modal -->
    <b-modal v-if="viewModalVisible" title="View User" id="view_modal" @hidden="resetModal" hide-footer size="md" ok-only> 
     <h2 class="text-center"> {{ selectedUser.name }}</h2>
      <div class="user-info">
      <!-- <div class="card align-items-center">
        <b-img style="width: 500px;" fluid rounded center thumbnail :src="'data:image/png;base64,'+selectedUser.logo" alt="User Logo"></b-img>
      </div> -->
      <div class="details-container text-center">
        <p><strong>User:</strong> {{ selectedUser.company_name }}</p>
        <p><strong>Email:</strong> {{ selectedUser.email }}</p>
        <p><strong>Contact Number:</strong> {{ selectedUser.contact_number }}</p>
        <p><strong>Role:</strong> {{ selectedUser.classification_description }}</p>
        <p><strong>Classification:</strong> {{ selectedUser.role_description }}</p>
        <p><strong>Address:</strong> {{ selectedUser.address }}</p>
      </div>
    </div>
    </b-modal>

    <!-- Delete Modal -->
    <BModal v-if="deleteModalVisible" title="Delete User" id="delete_modal" @hidden="resetModal" hide-footer>
      <p>Are you sure you want to set inactive the selected user??</p>
      <b-button variant="danger" @click="confirmDelete">Yes, Delete</b-button>
    </BModal>

    <b-modal
      v-if="addUpdateModalVisible"
      id="add_update_modal"
      @hidden="resetModal"
      :title="addUpdateModal.title"
      size="xl"
      hide-footer
    >
      <div class="container-fluid mt-2">
        <form @submit.prevent="addUpdateModal.button === 'Save' ? submitForm() : updateUser()" class="form-user">
          
          <!-- User Information Section -->
          <b-card class="mb-3">
            <h2 class="h4 mb-3 font-weight-normal text-center">User Information</h2>
            <div class="form-row">
              <!-- User Name -->
              <div class="form-group col-md-6">
                <label for="name">Name</label>
                <input type="text" v-model="user.name" id="name" class="form-control" required>
                <small id="nameHelp" class="form-text text-muted">Enter the name of your user.</small>
              </div>
              
              <!-- Classification -->
              <div class="form-group col-md-6">
                <label for="classification">Classification</label>
                <b-form-select v-model="user.user_classification_id" :options="user_classifications.selection" value-field="id" text-field="description">
                  <template #first>
                    <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
                <small id="classificationHelp" class="form-text text-muted">Select user classification</small>
              </div>
            </div>
          </b-card>

          <!-- Address Section -->
          <b-card class="mb-3">
            <h2 class="h4 mb-3 font-weight-normal text-center">Address</h2>
            <div class="form-row">
              <!-- Street -->
              <div class="form-group col-md-6">
                <label for="street">Street</label>
                <input type="text" v-model="user.street" id="street" class="form-control" required>
                <small id="streetHelp" class="form-text text-muted">Enter the street address</small>
              </div>
              
              <!-- City -->
              <div class="form-group col-md-6">
                <label for="city">City</label>
                <input type="text" v-model="user.city" id="city" class="form-control" required>
                <small id="cityHelp" class="form-text text-muted">Enter the city</small>
              </div>
            </div>
            
            <div class="form-row">
              <!-- State -->
              <div class="form-group col-md-4">
                <label for="state">State</label>
                <input type="text" v-model="user.state" id="state" class="form-control" required>
                <small id="stateHelp" class="form-text text-muted">Enter the state</small>
              </div>
              
              <!-- Zip Code -->
              <div class="form-group col-md-4">
                <label for="zip">Zip Code</label>
                <input type="text" v-model="user.zip_code" id="zip" class="form-control" required>
                <small id="zipHelp" class="form-text text-muted">Enter the zip code</small>
              </div>

              <div class="form-group col-md-4">
                <label for="country">Country</label>
                <input type="text" v-model="user.country" id="zip" class="form-control" required>
                <small id="countryHelp" class="form-text text-muted">Enter the Country</small>
              </div>
            </div>
          </b-card>

          <!-- Contact Information Section -->
          <b-card class="mb-3">
            <h2 class="h4 mb-3 font-weight-normal text-center">Contact Information</h2>
            <div class="form-row">
              <!-- Email Address -->
              <div class="form-group col-md-6">
                <label for="email">Email Address</label>
                <input type="email" v-model="user.email" id="email" class="form-control">
                <small id="emailHelp" class="form-text text-muted">Enter the email address</small>
              </div>
              
              <!-- Contact Number -->
              <div class="form-group col-md-6">
                <label for="contactNumber">Contact Number</label>
                <input type="text" v-model="user.contact_number" id="contactNumber" class="form-control" required>
                <small id="contactNumberHelp" class="form-text text-muted">Enter the contact number of your user.</small>
              </div>
            </div>
          </b-card>

          <!-- Organization Section -->
          <b-card class="mb-3">
            <h2 class="h4 mb-3 font-weight-normal text-center">Organization</h2>
            <div class="form-row">
              <!-- Company -->
              <div class="form-group col-md-6">
                <label for="company">Company</label>
                <b-form-select v-model="user.company_id" :options="companies.selection">
                  <template #first>
                    <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
                <small id="companyHelp" class="form-text text-muted">Select Company</small>
              </div>
              
              <!-- Department -->
              <div class="form-group col-md-6">
                <label for="department">Department</label>
                <b-form-select v-model="user.department_id" :options="departments.selection" value-field="id" text-field="description">
                  <template #first>
                    <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
                <small id="departmentHelp" class="form-text text-muted">Select Department</small>
              </div>
            </div>
            
            <div class="form-row">
              <!-- Role -->
              <div class="form-group col-md-6">
                <label for="role">Role</label>
                <b-form-select v-model="user.user_role_id" :options="user_roles.selection">
                  <template #first>
                    <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
                <small id="roleHelp" class="form-text text-muted">Select Role</small>
              </div>
            </div>
          </b-card>

          <!-- Submit Button -->
          <button class="btn btn-primary btn-block" type="submit">{{ addUpdateModal.button }}</button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  props: ['userPermissions'],
  data() {
    return {
      users: [],
      viewModalVisible: false,
      addUpdateModalVisible: false,
      deleteModalVisible: false,
      selectedUser: null,
      user: {
        id: '',
        name: '',
        company_name: '',
        contact_number: '',
        email: '',
        address: '',
        user_role_id:null,
        company_id:null,
        user_classification_id:null,
        street:null,
        city:null,
        state:null,
        zip_code:null,
        country:null,
      },
      header:[
        { key: 'name', label: 'Name' },
        { key: 'company_name', label: 'Company' },
        { key: 'contact_number', label: 'Contact #' },
        { key: 'email', label: 'Email' },
        { key: 'classification_description', label: 'Classification' },
        { key: 'role_description', label: 'Role' },
        { key: 'action', label: 'Actions' },
      ],
      addUpdateModal:{
        title:'Add User',
        button:'Save',
        function:'submitForm'
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true,
      companies:{
        selected:0,
        selection:[]
      },
      user_roles:{
        selected:0,
        selection:[]
      },
      filters:{
        company_id:null
      },
      user_classifications:{
        selection:[]
      },
      departments:{
        selection:[]
      },
      file: null,
      message: '',
      success: false,
      showModal:false
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getAllUsers();
    this.dropdownCompanies();
    this.dropdownUserRoles();
    this.getUserClassificationList();
    this.getDepartmentList();
  },
  methods: {
    async uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await axios.post('/import-users', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.message = response.data.message
        this.success = true;
      } catch (error) {
        this.message = error.response.data.error;
        this.success = false;
      }
    },
    getAllUsers() {
      this.users = []
      this.loading = true
      axios.get('/getAllUsersTable',{
        params: {
            perPage: this.perPage,
            currentPage: this.currentPage,
            filter: this.filter,
            company_id: this.filters.company_id
         },
      })
        .then(response => {
          this.users = response.data.data;
          this.loading = false
          this.totalRows = response.data.total
          console.log(this.users)
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    },
    viewModal(id, index, button) {
      this.addUpdateModal.title = 'Add User'
      this.addUpdateModal.button = 'Save'
      this.selectedUser = this.users.find(user => user.id === id);
      this.viewModalVisible = true;
    },
    editModal(id) {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Edit User'
      this.addUpdateModal.button = 'Update'
      this.addUpdateModal.function = 'updateUser'
      this.selectedUser = this.users.find(user => user.id === id);
      this.user = this.selectedUser

    },
    addUser() {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Add User'
      this.addUpdateModal.button = 'Save'
      this.addUpdateModal.function = 'submitForm'
      this.user = { ...this.$options.data().user };
    },
    updateUser() {
      const formData = new FormData();
      for (const key in this.selectedUser) {
        formData.append(key, this.selectedUser[key]);
      }
      axios.put(`/users/${this.selectedUser.id}`, this.selectedUser)
      .then(response => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.addUpdateModalVisible = false
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update user',
          });
      });
      
    },
    deleteModal(id) {
      this.selectedUser = this.users.find(user => user.id === id);
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      this.deleteModalVisible = false;
      const id = this.selectedUser.id;
      axios.post(`users/delete-confirm/${id}`)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          this.getAllUsers();
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to delete user',
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false
      this.selectedUser = null;
    },
    submitForm() {
        const formData = new FormData();
        for (const key in this.user) {
          formData.append(key, this.user[key]);
        }
  
        axios.post('/users', formData)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            this.getAllUsers()
            this.addUpdateModalVisible = false
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.error || 'Failed to save user',
            });
          });
      },
      handleLogoChange(event) {
        const file = event.target.files[0];
        this.user.logo = file;
      },
      handleUpdateLogoChange(event) {
        const file = event.target.files[0];
        this.selectedUser.logo = file;
      },
      dropdownCompanies(){
        axios.get('dropdowns/getAllCompanies').then(response=>{
          this.companies.selection = response.data
        })
      },
      dropdownUserRoles(){
        axios.get('dropdowns/getAllUserRoles').then(response=>{
          this.user_roles.selection = response.data
        })
      },
      clearFilters(){
        this.filter = null
        this.filters.company_id = null
      },
      hasPermission(permissionName) {
      // return true // make true for a while
          return this.userPermissions.some(permission => permission.name === permissionName);
      },
      getUserClassificationList() {
        axios.get('/getUserClassificationList')
          .then(response => {
            this.user_classifications.selection = response.data
          })
          .catch(error => {
            console.error('Error fetching classifications:', error);
          });
      },
      getDepartmentList() {
        axios.get('/getDepartmentList')
          .then(response => {
            this.departments.selection = response.data

          })
          .catch(error => {
            console.error('Error fetching departments:', error);
          });
      },
      
      
  },
  watch:{
    currentPage(val){
      this.getAllUsers()
    }
  }
};
</script>
<style scoped>
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}


.flag-icon {
  width: 20px;
  height: auto;
}

h2.h4 {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

b-card {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
}
</style>
