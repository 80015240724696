<template>
  <b-container class="d-flex justify-content-center align-items-center vh-100">
    <b-row class="w-100 justify-content-center">
      <b-col md="6" class="mx-auto">
        <b-card>
          <h1 class="text-center">Consent Form for A2P Messaging</h1>
          <p>
            Welcome to AiLA TEXT! We would like to send you important updates, promotions,
            and alerts via SMS. To comply with regulations and ensure you have a positive
            experience, we need your consent to receive these messages.
          </p>
          <b-form @submit.prevent="submitForm">
            <b-form-group label="Full Name:" label-for="fullName">
              <b-form-input id="fullName" v-model="form.fullName" required :disabled="isDisabled"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone Number:" label-for="phoneNumber">
              <b-form-input
                id="phoneNumber"
                v-model="form.phoneNumber"
                required
                :disabled="isDisabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Email Address (optional):" label-for="emailAddress">
              <b-form-input
                id="emailAddress"
                v-model="form.emailAddress"
                type="email"
                :disabled="isDisabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox id="consent" v-model="form.consent" required>
                I agree to receive SMS messages.
              </b-form-checkbox>
            </b-form-group>
            <p>
              By submitting this form, you agree to our
              <a href="#" @click="showModal = true" style="color: blue"
                >Terms and Conditions</a
              >.
            </p>
            <b-button type="submit" variant="primary" block>Submit</b-button>
          </b-form>
          <!-- <h2 class="mt-4">Terms and Conditions</h2>
          <p>
            
           
          </p> -->
          <b-modal v-model="showModal" title="Terms and Conditions" size="lg">
            <ul>
              <li>
                Message frequency may vary based on your interaction with our service.
              </li>
              <li>Standard message and data rates may apply.</li>
              <li>
                You can reply STOP to any message to opt-out of receiving future messages.
              </li>
              <li>
                For help, you can reply HELP to any message or contact our support team at
                [Support Contact Information].
              </li>
            </ul>
            <h2>Privacy Policy</h2>
            <p>
              We value your privacy and will handle your information in accordance with
              our <a href="#">Privacy Policy</a>. Your data will not be shared with third
              parties without your explicit consent.
            </p>
            <h2>Contact Us</h2>
            <p>If you have any questions or need assistance, please contact us at:</p>
            <ul>
              <li>Email: [Your Email]</li>
              <li>Phone: [Your Phone Number]</li>
              <li>Address: [Your Address]</li>
            </ul>
            <b-button variant="primary" @click="showModal = false">Close</b-button>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
export default {
  props: ["user"],
  data() {
    return {
      form: {
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        consent: false,
      },
      showModal: false,
      disabled:false
    };
  },
  methods: {
    submitForm() {
      if (this.form.consent) {
        axios
          .post("/submit-consent", {
            contact_number: this.form.phoneNumber,
          })
          .then((response) => {
            Swal.fire(response.data.message, response.data.body, response.data.icon);
          });
      } else {
        Swal.fire("Please tick the checkbox");
      }
    },
  },
  mounted(){
    if(this.user != 'NA'){
      this.form.fullName = this.user.name ?? '';
      this.form.phoneNumber = this.user.contact_number ?? '',
      this.form.emailAddress = this.user.email ?? ''
      this.isDisabled = true
    }
    
  },
  watch:{
    user(val){
      
    }
  }
};
</script>

<style scoped>
.consent-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>
