import { render, staticRenderFns } from "./UserProfileComponent.vue?vue&type=template&id=078fced7&scoped=true"
import script from "./UserProfileComponent.vue?vue&type=script&lang=js"
export * from "./UserProfileComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078fced7",
  null
  
)

export default component.exports