<template>
  <div class="mt-4">
    <h2>Company Details</h2>
    <b-row class="my-2">
      <b-col>
        <b-button  @click="addCompany()" v-b-modal="'add_update_modal'" variant="primary" size="sm">+ ADD</b-button>
      </b-col>
      <b-col>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search Company Name"
            @input="getAllCompanies"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table striped hover :items="companies" :fields="header"
      :busy="loading"
      :per-page="perPage"
      stacked="md"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(logo)="data">
        <b-img width="50px" rounded :src="'data:image/png;base64,'+data.item.logo"  alt="Company Logo"></b-img>
      </template>
      <template #cell(action)="data">
          <b-button variant="warning" @click="viewCompany(data.item.id,'',$event.target)" v-b-modal="'view_modal'" size="sm"><i class="fas fa-eye"></i></b-button>
          <!-- <b-button variant="secondary" @click="editCompanySchedule(data.item.id)"  v-b-modal="'schedule_modal'" size="sm" ><i class="fas fa-clock"></i></b-button> -->
          <b-button variant="primary" @click="editCompany(data.item.id)" v-b-modal="'add_update_modal'" size="sm"><i class="fas fa-edit"></i></b-button>
          <b-button variant="danger" @click="deleteCompany(data.item.id)"  v-b-modal="'delete_modal'" size="sm" ><i class="fas fa-trash"></i></b-button>
        </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  
    <!-- View Modal -->
    <b-modal v-if="viewModalVisible" title="View Company" id="view_modal" @hidden="resetModal" hide-footer size="lg" ok-only> 
     <h2 class="text-center"> {{ selectedCompany.name }}</h2>
      <div class="company-info">
      <div class="card align-items-center">
        <b-img style="width: 500px;" fluid rounded center thumbnail :src="'data:image/png;base64,'+selectedCompany.logo" alt="Company Logo"></b-img>
      </div>
      <div class="details-container">
        <p><strong>Address:</strong> {{ selectedCompany.address }}</p>
        <p><strong>Website:</strong> <a :href="selectedCompany.website" target="_blank">{{ selectedCompany.website }}</a></p>
        <p><strong>Email:</strong> {{ selectedCompany.email }}</p>
        <p><strong>Contact Number:</strong> {{ selectedCompany.contact_number }}</p>
        <p><strong>Description:</strong> {{ selectedCompany.description }}</p>
      </div>
    </div>
    </b-modal>

    <!-- Schedule Modal -->
    <!-- <b-modal v-if="scheduleModalVisible" title="Company Schedule" id="schedule_modal" @hidden="resetModal" hide-footer size="lg" ok-only> 
     <h2 class="text-center"> {{ selectedCompany.name }}</h2>
      <div class="company-info">
      <div class="details-container">
        <div class="row">
          <div class="col-4 d-flex justify-content-left"><b>Day</b></div>
          <div class="col-4 d-flex justify-content-center"><b>Start Time</b></div>
          <div class="col-4 d-flex justify-content-center"><b>End time</b></div>
        </div>
        <div class="form-row" v-for="(day, index) in days" :key="index">
          <div class="col-4">
            <b-form-checkbox v-model="day.isActive">{{ day.name }}</b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-timepicker v-model="day.startTime" :disabled="!day.isActive"></b-form-timepicker>
          </div>
          <div class="col-4">
            <b-form-timepicker v-model="day.endTime" :disabled="!day.isActive"></b-form-timepicker>
          </div>
        </div>
        <div class="footer-buttons">
          <b-button class="m-1" variant="primary" @click="saveSchedule">Save</b-button>
          <b-button class="m-1" variant="secondary" @click="cancelSchedule">Cancel</b-button>
        </div>
      </div>
    </div>
    </b-modal> -->

    <!-- Delete Modal -->
    <BModal v-if="deleteModalVisible" title="Delete Company" id="delete_modal" @hidden="resetModal" hide-footer>
      <p>Are you sure you want to delete this company?</p>
      <b-button variant="danger" @click="confirmDelete">Yes, Delete</b-button>
    </BModal>

    <BModal  v-if="addUpdateModalVisible" id="add_update_modal" @hidden="resetModal" :title="addUpdateModal.title" size="lg" hide-footer> 
      <div class="container-fluid mt-2">
      <form @submit.prevent="addUpdateModal.button == 'Save' ? submitForm() : updateCompany()" class="form-company">
  
        <!-- Company Information Section -->
        <h1 class="h3 mb-3 font-weight-normal text-center">Company Information</h1>
  
        <div class="form-row">
          <!-- Company Name -->
          <div class="form-group col-md-6">
            <label for="name">Company Name</label>
            <input type="text" v-model="company.name" id="name" class="form-control" required>
            <small id="nameHelp" class="form-text text-muted">Enter the name of your company.</small>
          </div>
  
          <!-- Address -->
          <div class="form-group col-md-6">
            <label for="address">Address</label>
            <textarea v-model="company.address" id="address" class="form-control" required></textarea>
            <small id="addressHelp" class="form-text text-muted">Enter the address of your company.</small>
          </div>
        </div>
  
        <div class="form-row">
          <!-- Website -->
          <div class="form-group col-md-6">
            <label for="website">Website</label>
            <input type="text" v-model="company.website" id="website" class="form-control">
            <small id="websiteHelp" class="form-text text-muted">Enter the website of your company (if applicable).</small>
          </div>
  
          <!-- Email -->
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="email" v-model="company.email" id="email" class="form-control" required>
            <small id="emailHelp" class="form-text text-muted">Enter the email address of your company.</small>
          </div>
        </div>
  
        <div class="form-row">
          <!-- Contact Number -->
          <div class="form-group col-md-6">
            <label for="contactNumber">Contact Number</label>
            <input type="text" v-model="company.contact_number" id="contactNumber" class="form-control" required>
            <small id="contactNumberHelp" class="form-text text-muted">Enter the contact number of your company.</small>
          </div>

          <div class="form-group col-md-6">
            <label for="contactNumber">Twilio Number</label>
            <input type="text" v-model="company.twilio_number" id="contactNumber" class="form-control" required>
            <small id="contactNumberHelp" class="form-text text-muted">Enter the <b>TWILIO</b> number of your company.</small>
          </div>
        </div>

        <div class="form-row">
            <!-- Description -->
            <div class="form-group col-md-12">
            <label for="description">Description</label>
            <textarea v-model="company.description" id="description" class="form-control"></textarea>
            <small id="descriptionHelp" class="form-text text-muted">Provide a brief description of your company (optional).</small>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="example-datepicker">Office Start Time</label>
            <b-form-timepicker v-model="company.start_time" locale="en"></b-form-timepicker>
          </div>
          <div class="form-group col-md-6">
            <label for="example-datepicker">Office End Time</label>
            <b-form-timepicker v-model="company.end_time" locale="en"></b-form-timepicker>
          </div>
        </div>
  
        <!-- Logo Section -->
        <div class="form-group">
          <label for="logo">Logo</label>
          <div class="custom-file">
            <input type="file" ref="logoInput" @change="handleLogoChange" class="custom-file-input" id="logo">
            <label class="custom-file-label" for="logo">Choose file</label>
          </div>
          <small id="logoHelp" class="form-text text-muted">Upload your company logo.</small>
        </div>
  
        <!-- Submit Button -->
        <button class="btn btn-primary btn-block" type="submit">{{addUpdateModal.button}}</button>
      </form>
    </div>
    </BModal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
export default {
  data() {
    return {
      companies: [],
      viewModalVisible: false,
      scheduleModalVisible:false,
      addUpdateModalVisible: false,
      deleteModalVisible: false,
      selectedCompany: null,
      company: {
        id: '',
        name: '',
        address: '',
        website: '',
        email: '',
        contact_number: '',
        twilio_number: '',
        description: '',
        logo: null,
        start_time:null,
        end_time:null
      },
      days: [
        { name: 'Monday', isActive: false, startTime: null, endTime: null },
        { name: 'Tuesday', isActive: false, startTime: null, endTime: null },
        { name: 'Wednesday', isActive: false, startTime: null, endTime: null },
        { name: 'Thursday', isActive: false, startTime: null, endTime: null },
        { name: 'Friday', isActive: false, startTime: null, endTime: null },
        { name: 'Saturday', isActive: false, startTime: null, endTime: null },
        { name: 'Sunday', isActive: false, startTime: null, endTime: null }
      ],
      header:[
        { key: 'name', label: 'Name' },
        { key: 'address', label: 'Address' },
        { key: 'website', label: 'Website' },
        { key: 'email', label: 'Email' },
        { key: 'contact_number', label: 'Contact #' },
        { key: 'description', label: 'Description' },
        { key: 'logo', label: 'Logo' },
        { key: 'action', label: 'Actions' },
      ],
      addUpdateModal:{
        title:'Add Company',
        button:'Save',
        function:'submitForm'
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loading:true
    };
  },
  components:{
    BModal
  },
  mounted() {
    this.getAllCompanies();
  },
  methods: {
    getAllCompanies() {
      this.companies = []
      this.loading = true
      axios.get('/getAllCompanies',{
        params: {
            perPage: this.perPage,
            currentPage: this.currentPage,
            filter: this.filter,
         },
      })
        .then(response => {
          this.companies = response.data.data;
          this.loading = false
          this.totalRows = response.data.total
          console.log(this.companies)
        })
        .catch(error => {
          console.error('Error fetching companies:', error);
        });
    },
    viewCompany(id, index, button) {
      this.addUpdateModal.title = 'Add Company'
      this.addUpdateModal.button = 'Save'
      this.selectedCompany = this.companies.find(company => company.id === id);
      this.viewModalVisible = true;
    },
    editCompanySchedule(id) {
      this.selectedCompany = this.companies.find(company => company.id === id);
      this.scheduleModalVisible = true;
    },
    editCompany(id) {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Edit Company'
      this.addUpdateModal.button = 'Update'
      this.addUpdateModal.function = 'updateCompany'
      this.selectedCompany = this.companies.find(company => company.id === id);
      this.company = this.selectedCompany

    },
    addCompany() {
      this.addUpdateModalVisible = true
      this.addUpdateModal.title = 'Add Company'
      this.addUpdateModal.button = 'Save'
      this.addUpdateModal.function = 'submitForm'
      this.company = { ...this.$options.data().company };
    },
    updateCompany() {
      const formData = new FormData();
      for (const key in this.selectedCompany) {
        formData.append(key, this.selectedCompany[key]);
      }
      axios.put(`/companies/${this.selectedCompany.id}`, this.selectedCompany)
      .then(response => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        this.addUpdateModalVisible = false
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to update company',
          });
      });
      
    },
    deleteCompany(id) {
      this.selectedCompany = this.companies.find(company => company.id === id);
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      this.deleteModalVisible = false;
      const id = this.selectedCompany.id;
      axios.post(`/delete-confirm/${id}`)
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          this.getAllCompanies();
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.error || 'Failed to delete company',
          });
        });
    },
    resetModal() {
      this.viewModalVisible = false;
      this.editModalVisible = false;
      this.deleteModalVisible = false;
      this.addUpdateModalVisible = false
      this.selectedCompany = null;
    },
    submitForm() {
        const formData = new FormData();
        for (const key in this.company) {
          formData.append(key, this.company[key]);
        }
  
        axios.post('/companies', formData)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
            });
            this.getAllCompanies()
            this.addUpdateModalVisible = false
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.error || 'Failed to save company',
            });
          });
      },
      handleLogoChange(event) {
        const file = event.target.files[0];
        this.company.logo = file;
      },
      handleUpdateLogoChange(event) {
        const file = event.target.files[0];
        this.selectedCompany.logo = file;
      },
      saveSchedule() {
      // Implement save functionality here
        this.scheduleModalVisible = false;
        console.log("Save button clicked");
      },
      cancelSchedule() {
        // Implement cancel functionality here
        this.scheduleModalVisible = false;
        console.log("Cancel button clicked");
      }
  },
  watch:{
    currentPage(val){
      this.getAllCompanies()
    }
  }
};
</script>
<style scoped>
.company-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.company-table th, .company-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.company-table th {
  background-color: #f2f2f2;
}

.edit-form {
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.logo-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  display: block;
}

.footer-buttons {
  margin-top:20px;
  display: flex;
  justify-content: flex-end;
}
</style>