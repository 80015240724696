var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"height":"88vh"}},[_c('div',{staticClass:"col-3 pl-0 pr-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"chat-messages inbox-box",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"search-header"},[_vm._m(0),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_params),expression:"search_params"}],staticClass:"search-input",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.search_params)},on:{"input":function($event){if($event.target.composing)return;_vm.search_params=$event.target.value}}})]),_vm._v(" "),(_vm.hasPermission('caregiver tab'))?_c('b-tabs',{attrs:{"pills":"","card":"","align":"center","active-nav-item-class":"custom-active-tab bg-light text-dark"}},[_c('b-tab',{staticClass:"text-dark",attrs:{"title":"Caregiver","active":""},on:{"click":function($event){_vm.room_id = null;
            _vm.setTab('caregiver');}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            Caregiver\n            "),(_vm.notification_counts.caregiver > 0)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(_vm.notification_counts.caregiver))]):_vm._e()]},proxy:true}],null,false,1571900450)},[_vm._v(" "),_vm._l((_vm.users),function(person){return (!!person.last_session)?_c('ul',{staticClass:"list-unstyled mb-0"},[(
                person.id != _vm.user.id &&
                person.user_role_id == 2 &&
                (person.last_session.claimed_by == _vm.user.id ||
                  person.last_session.claimed_by == null) &&
                person.last_session.resolved_by == null
              )?_c('li',{staticClass:"p-2 inbox-box-msg my-3",style:(!!person.hasNewMessage ? person.hasNewMessage : null),on:{"click":function($event){return _vm.setRecipient(person)}}},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":`https://ui-avatars.com/api/?name=${person.name}&background=random`,"alt":"avatar","width":"60"}}),_vm._v(" "),_c('div',{staticClass:"pt-1 pl-2 name-status"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(person.name))]),_vm._v(" "),_c('span',{staticClass:"color-box-a"},[_vm._v("ID: "),_c('i',[_vm._v(_vm._s(person.session_id))])])]),_vm._v(" "),_c('p',{staticClass:"small text-muted p-0 m-0"},[_vm._v("\n                      "+_vm._s(!!person.last_session
                          ? _vm.formatDateTime(person.last_session.created_at)
                          : "No Conversation at the moment")+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"color-box-a p-2 m-0",style:({ backgroundColor: person.message_type[0].hex_code })},[_vm._v("\n                      "+_vm._s(!!person.last_session ? person.message_type[0].description : "")+"\n                    ")])])]),_vm._v(" "),_c('div',{staticClass:"pt-1 name-status"},[(person.last_message && person.last_message.message)?_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("\n                    "+_vm._s(_vm.calculateDuration(person.last_message.created_at))+"\n                  ")]):_vm._e(),_vm._v(" "),_c('span',{class:`badge ${!!person.badgeStatus ? person.badgeStatus : null}`},[_vm._v(_vm._s(!!person.statusText ? person.statusText : null))])])])]):_vm._e()]):_vm._e()})],2),_vm._v(" "),(_vm.hasPermission('carecircle tab'))?_c('b-tab',{attrs:{"title":"Coordinator"},on:{"click":function($event){return _vm.setTab('Caregiver')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            Care Circle\n            "),(_vm.notification_counts.coordinator > 0)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(_vm.notification_counts.coordinator))]):_vm._e()]},proxy:true}],null,false,965018143)},[_vm._v(" "),_vm._l((_vm.users),function(person){return _c('ul',{staticClass:"list-unstyled mb-0"},[(person.id != _vm.user.id && person.user_role_id == 1)?_c('li',{staticClass:"p-2 inbox-box-msg my-3",style:(!!person.hasNewMessage ? person.hasNewMessage : null),on:{"click":function($event){_vm.setRecipient(person);
                _vm.recipient_id = person.id;}}},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":`https://ui-avatars.com/api/?name=${person.name}&background=random`,"alt":"avatar","width":"60"}}),_vm._v(" "),_c('div',{staticClass:"pt-1 pl-2 name-status"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(person.name))]),_vm._v(" "),_c('span'),_vm._v(_vm._s(person.session_id)+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"small text-muted"},[_vm._v("\n                      "+_vm._s(!!person.last_session
                          ? _vm.formatDateTime(person.last_session.created_at)
                          : "No Conversation at the moment")+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"color-box-a",style:({ backgroundColor: person.message_type[0].hex_code })},[_vm._v("\n                      "+_vm._s(!!person.last_session
                          ? _vm.formatDateTime(person.message_type[0].description)
                          : "No Conversation at the moment")+"\n                    ")])])]),_vm._v(" "),_c('div',{staticClass:"pt-1 name-status"},[(person.last_message && person.last_message.message)?_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("\n                    "+_vm._s(_vm.calculateDuration(person.last_message.created_at))+"\n                  ")]):_vm._e()])])]):_vm._e()])})],2):_vm._e(),_vm._v(" "),(_vm.hasPermission('follow-up care tab'))?_c('b-tab',{attrs:{"title":"Room"},on:{"click":function($event){_vm.getAllRooms();
            _vm.setTab('room');}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            Follow-Up Care\n            "),(_vm.notification_counts.room > 0)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(_vm.notification_counts.room))]):_vm._e()]},proxy:true}],null,false,1934750228)},[_vm._v(" "),_vm._l((_vm.rooms),function(room){return _c('ul',{staticClass:"list-unstyled mb-0"},[_c('li',{staticClass:"p-2 inbox-box-msg my-3",on:{"click":function($event){_vm.setRoomId(room.id);
                _vm.room_id = room.id;}}},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":`https://ui-avatars.com/api/?name=Room1&background=random`,"alt":"avatar","width":"60"}}),_vm._v(" "),_c('div',{staticClass:"pt-1 pl-2 name-status"},[_c('p',{staticClass:"fw-bold mb-0"},[_c('b',[_vm._v(_vm._s(_vm.formattedNames(room.all_users)))]),_vm._v(" "),_c('span',{staticClass:"badge badge-pill badge-primary"},[_vm._v("room")])])])])])])])})],2):_vm._e()],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"col m-0 p-0"},[_c('ChatWindowComponent',{ref:"child",style:({ display: !!_vm.chatSessionId ? 'block' : 'none' }),attrs:{"user":_vm.user,"chatSessionId":_vm.chatSessionId,"recipient":_vm.recipient,"room_id":_vm.room_id,"selected_tab":_vm.selected_tab,"userPermissions":_vm.userPermissions,"sessionId":_vm.sessionId},on:{"update-notification-count":_vm.updateNotificationCount,"update-badge-status":_vm.updateBadgeStatus,"update-inbox":_vm.updateInbox}})],1),_vm._v(" "),(!!_vm.recipient_id && _vm.showProfile)?_c('div',{staticClass:"col-2 py-4",staticStyle:{"height":"83vh"}},[_c('div',{staticClass:"inbox-box",staticStyle:{"height":"83vh"}},[_c('b-card',{staticStyle:{"max-width":"20rem","border-radius":"20px"},attrs:{"no-body":"","img-src":`https://ui-avatars.com/api/?name=${
          !!_vm.recipient ? _vm.recipient.name : ''
        }&background=random`,"img-alt":"Image","img-top":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(!!_vm.recipient ? _vm.recipient.name : ""))])]},proxy:true}],null,false,1557327708)},[_c('button',{staticClass:"close-button",on:{"click":function($event){_vm.showProfile = false}}},[_vm._v("×")]),_vm._v(" "),_vm._v(" "),_c('b-card-body',{staticStyle:{"border-radius":"20px"}},[_c('b-card-title',[_vm._v(_vm._s(!!_vm.recipient ? _vm.recipient.contact_number : ""))]),_vm._v(" "),_c('b-card-sub-title',{staticClass:"mb-2"},[_c('i',{staticClass:"fas fa-envelope"}),_vm._v(":\n            "+_vm._s(!!_vm.recipient ? _vm.recipient.email : ""))]),_vm._v(" "),_c('b-card-text',[_c('i',{staticClass:"fas fa-home"}),_vm._v(": "+_vm._s(!!_vm.recipient ? _vm.recipient.address : "")+"\n          ")])],1),_vm._v(" "),_c('b-list-group',{staticStyle:{"border-radius":"20px"},attrs:{"flush":""}},[_c('b-list-group-item',[_c('i',{staticClass:"fas fa-birthday-cake"}),_vm._v(": [Birthday Here]")]),_vm._v(" "),_c('b-list-group-item',[_vm._v("Other Details")]),_vm._v(" "),_c('b-list-group-item',[_vm._v("Other Details")])],1)],1)],1)]):_vm._e(),_vm._v(" "),(!_vm.showProfile)?_c('div',{staticClass:"col-auto py-4 px-0 ma-0",staticStyle:{"height":"83vh"}},[_c('b-button',{on:{"click":function($event){_vm.showProfile = true}}},[_c('i',{staticClass:"fas fa-user"})])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-icon"},[_c('i',{staticClass:"fas fa-search"})])
}]

export { render, staticRenderFns }