<template>
    <div class="row">
        <div class="col-12">
            <div>
                <b-modal id="modal-1" title="Choose Message Type" size="xl" centered hide-footer no-close-on-backdrop no-close-on>
                    <template #modal-header>
                        <h5 class="modal-title center" >Choose Message Type</h5>
                    </template> 
                    <div class="d-flex flex-wrap justify-content-around p-3">
                        <b-button 
                        class="col-5 mb-3 custom-button" 
                        v-for="n in messageTypes" 
                        :key="n" 
                        variant="primary"
                        @click="submitSelectedMessageType(n)"
                        :style="{backgroundColor: n.hex_code}"
                        >
                        {{ n.description }}
                        </b-button>
                    </div>
                </b-modal>
            </div>
            <div class="card p-3" style="height: max-content;">
                <div style="display: flex; align-items: center; width: 100%; justify-content: space-between;">
                <div style="display: flex; align-items: center;">
                    <div style="font-weight: bold;"></div>
                    <div class="stopwatch">
                        Waiting time: <div class="time">{{ formattedTime }}</div>
                    </div>
                    <!-- <div style="color: grey; font-size: 14px;" class="m-2">assigned to <b>Charles</b></div> -->
                </div>
                <div style="display: flex;">
                    <button v-if="!chatStarted && !invalidUrl" class="btn btn-primary" @click="createSession()" >Start Chat</button>
                    <!-- <button style="background: transparent; border: none; cursor: pointer;" class="m-2">
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                        <span>Claim</span>
                    </button> -->
                    <!-- <button style="background: transparent; border: none; cursor: pointer;" class="m-2">
                        <i class="fa fa-exchange" aria-hidden="true"></i>
                        <span>Transfer</span>
                    </button> -->
                    <!-- <button style="background: transparent; border: none; cursor: pointer;" class="m-2">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <span>Resolve</span>
                    </button> -->
                    <!-- <button style="background: transparent; border: none; cursor: pointer;" class="m-2">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button> -->
                </div>     
            </div>
            </div>
        </div>
        <div class="col-12" style="max-height:60vh">
            <div class="card transparent-card" style="max-height:60vh; border-radius: 20px;">
                <div class="card-body transparent-card p-0">
                    <ul class="list-unstyled" style="height:100%; overflow-y:scroll" v-chat-scroll="{always: false, smooth: true}">
                        <li  v-for="message in allMessage">
                            <!-- <span class="justify-content-center">{{message.session_id}}</span>    -->
                            <p>
                                    <p v-for="chatMessage in message.message" :class="chatMessage.user_id != user.id ? 'px-2 text-left':'px -2 text-right'">  
                                        <strong>{{chatMessage.name}}</strong>    
                                        <br/>      
                                        <img v-if="chatMessage.user_id != user.id" :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`" alt="avatar"
                                        class="rounded-circle shadow-1-strong" width="35"> 
                                    
                                        <span v-if="checkIfHasString(chatMessage.message)" :class="chatMessage.user_id != user.id ? 'bg-light message-box-other px-2 py-1':'bg-light message-box px-2 py-1'">
                                            {{chatMessage.message}}</span> 
                                        <span v-else><img width="50%" :src="chatMessage.message" alt="Image" @click="openModal(chatMessage.message)"></span>
                                        
                                        <img v-if="chatMessage.user_id == user.id" :src="`https://ui-avatars.com/api/?name=${chatMessage.name}&background=random`" alt="avatar"
                                        class="rounded-circle shadow-1-strong" width="35"> 
                                    </p>
                                    
                            </p>               
                        </li>
                    </ul>
                </div>
            </div>
            <span class="text-muted" v-if="typingUser">{{typingUser.name}} is styping...</span>
        </div>
        <div class="col-12 input-with-icon">
            <b-input-group>
                <!-- Input field -->
                <b-form-input 
                :disabled="allowType"
                v-if="!showFile"
                @keydown="getTypingEvent"
                @keyup.enter="saveMessage"
                v-model="newMessage" 
                type="text" name="message" placeholder="Enter your message..." class="form-control"
                style="border-start-start-radius: 10px; padding: 10px"
                ></b-form-input>

                <!-- Suffix icons -->
                <b-input-group-append>
                <!-- Clickable icon 1 -->
                <b-input-group-text v-if="!showFile">
                    <i class="fas fa-image"  @click="showFile = true"></i>
                </b-input-group-text>

                <!-- Clickable icon 2 -->
                <b-input-group-text v-if="!showFile">
                    <i class="fas fa-paper-plane"  @click="saveMessage"></i>
                </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-input-group>
                    <b-form-file
                        v-if="showFile"
                        v-model="newMessageFile"
                        :state="Boolean(newMessageFile)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        class="mr-2"
                    ></b-form-file>

                    <!-- Add a button to clear the selected file -->
                    <b-button
                        v-if="showFile"
                        variant="danger"
                        size="sm"
                        @click="showFile = false"
                    >
                        Clear
                    </b-button>
                    <b-button
                        v-if="showFile"
                        variant="primary"
                        size="sm"
                        @click="saveMessage"
                        class="ml-1"
                    >
                        Send
                    </b-button>
                </b-input-group>
        </div>
        
        <b-modal v-model="showModal" title="Image Preview" @hide="closeModal" size="lg" centered hide-footer> 
            <img :src="selectedImage" class="img-fluid" alt="Preview">
        </b-modal>
    </div>
</template>

<script>
import Echo from 'laravel-echo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';

    export default {
        props:['user','chatSessionId','recipient','flag_text'],
        data(){
            return {
                allMessage:[],
                newMessage:'',
                users:[],
                typingTimer:'false',
                typingUser:'',
                recipient_id:'',
                params:{
                    recipient_id:null,
                    timestamp:null,
                    session_id:null,
                },
                startTime: 0,
                elapsedTime: 0,
                isRunning: false,
                chatStarted:false,
                session_id:null,
                session_details:{
                    id:null,
                    session_id:null,
                    message_type:null
                },
                invalidUrl:false,
                showModal:false,
                selectedImage:null,
                sentImage:null,
                newMessageFile:'',
                showFile:false,
                allowType:false,
                defaultMessage:'Happy Day!, Is anyone Available to talk to? Thank you',
                messageTypes:[],
                selectedMessageType:null
            }
        },
        computed: {
            formattedTime() {
            const totalMilliseconds = this.isRunning
                ? Date.now() - this.startTime + this.elapsedTime
                : this.elapsedTime;

            const minutes = Math.floor(totalMilliseconds / 60000);
            const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
            const milliseconds = (totalMilliseconds % 1000).toFixed(0);

            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            },
        },
        async mounted(){
            await this.chooseMessageType()
            this.$bvModal.show('modal-1');
            // this.checkIfFromText ()
            // // await this.checkIfHasOnGoingSession()
            // this.getMessages();
            window.Echo.join('chats')
            .listenForWhisper('typing',user=>{
                this.typingUser = user;
                if(this.typingUser){
                    clearTimeout(this.typingTimer);
                }
                this.typingTimer = setTimeout(()=>{
                    this.typingUser = false;
                },1500)
            })
            .listen('SendMessageEvent',(event)=>{
                    console.log(event.message.session_id)
                    if(event.message.session_id == this.session_details.id){
                        Object.assign(event.message,{
                        name:event.user.name
                        })
                        this.allMessage[0].message.push(event.message)
                        this.allMessage[0].user = event.user
                    }        
            })

            window.Echo.join('resolve-chats')
            .listen('EndMessageEvent',(event)=>{
                    console.log(event)
                   if(event.session_id == this.session_details.id){
                        Swal.fire('This session has been resolved','','success').then(e=>{
                            this.flag_text = false
                            window.location.href = 'redirection'
                        })
                   }
            })
            window.Echo.join('transfer-chats')
            .listen('TransferMessageEvent',(event)=>{
                   if(event.session.old_session_id == this.session_details.id){
                        Swal.fire('You have been transferred to another Coordinator','','success').then(e=>{
                            this.session_details = event.session
                        })
                   }
            })
        },
        methods:{
            updateTimer() {
            // Update the timer every 100 milliseconds
            setTimeout(() => {
                this.elapsedTime += 1000;
                this.updateTimer();
            }, 1000);
            },
            async getMessages(){
                let params = {
                    params: {
                        selected_user:!!this.flag_text.fromBy ? this.flag_text.fromBy : this.params.recipient_id,
                        session_id:this.session_details.id
                    }
                }
                await axios.get('messages',params).then(response=>{
                    this.allMessage = response.data;
                })
            },
            saveMessage(msg = null){
                // if(msg != null && this.defaultMessage != null) {
                //     this.newMessage = this.defaultMessage 
                //     this.defaultMessage = null
                // }
                if (!!this.newMessage || !!this.newMessageFile){
                    const lastIndex = this.allMessage[this.allMessage.length-1];
                    if(!!lastIndex){
                        const id = this.allMessage.findIndex(obj => obj.id === this.session_id);
                        let fileHolder = null;

                        if (this.newMessageFile) {
                            const reader = new FileReader();

                            // Create a promise that resolves when the onload event is triggered
                            const onloadPromise = new Promise((resolve) => {
                                reader.onload = (event) => {
                                    fileHolder = event.target.result;
                                    
                                    resolve(fileHolder);
                                };
                            });

                            // Read the selected file as a data URL (base64)
                            reader.readAsDataURL(this.newMessageFile);

                            // Wait for the promise to resolve before proceeding
                            // await onloadPromise;

                            // Now fileHolder has the correct value
                            onloadPromise.then(res=>{
                                this.sentImage = res;
                                lastIndex.message.push({
                                    message: this.newMessageFile ? this.sentImage : this.newMessage,
                                    user_id: this.user.id,
                                    name: this.user.name,
                                });
                            })
                            
                        }
                        else{
                            lastIndex.message.push({
                                message: this.newMessageFile ? this.sentImage : this.newMessage,
                                user_id: this.user.id,
                                name: this.user.name,
                            });
                        }
                        lastIndex.user = this.user
                    }
                    else{
                        let fileHolder = null;
                        if (this.newMessageFile) {
                            const reader = new FileReader();

                            // Create a promise that resolves when the onload event is triggered
                            const onloadPromise = new Promise((resolve) => {
                                reader.onload = (event) => {
                                    fileHolder = event.target.result;
                                    
                                    resolve(fileHolder);
                                };
                            });

                            // Read the selected file as a data URL (base64)
                            reader.readAsDataURL(this.newMessageFile);

                            // Wait for the promise to resolve before proceeding
                            // await onloadPromise;

                            // Now fileHolder has the correct value
                            onloadPromise.then(res=>{
                                this.sentImage = res;
                                this.allMessage.push({
                                    id:this.session_details.id,
                                    session_id:this.session_details.session_id,
                                    message:[{
                                        message:this.newMessageFile ? this.sentImage : this.newMessage,
                                        user_id:this.user.id,
                                        name:this.user.name
                                    }],
                                    user:this.user
                                })
                            })
                            
                        }
                        else{
                            this.allMessage.push({
                                id:this.session_details.id,
                                session_id:this.session_details.session_id,
                                message:[{
                                    message:this.newMessageFile ? this.sentImage : this.newMessage,
                                    user_id:this.user.id,
                                    name:this.user.name
                                }],
                                user:this.user
                            })
                        }
                        
                    }         
                    
                    const formData = new FormData();
                    formData.append('message',!this.showFile ? this.newMessage : this.newMessageFile)
                    formData.append('recipient_id',!!this.flag_text.fromBy ? this.flag_text.fromBy : this.params.recipient_id)
                    formData.append('session_id',this.session_details.id)

                    axios.post('/messages',formData).then(response=>{
                        this.newMessage = '';
                        this.showFile = false
                        this.newMessageFile = ''      
                    })

                    // axios.post('/messages',{
                    //     message:this.newMessage,
                    //     recipient_id:this.params.recipient_id,
                    //     session_id: this.session_details.id
                    // }).then(response=>{
                    //     this.newMessage = '';      
                    // })
                }
            },
            getTypingEvent(){
                window.Echo.join('chats')
                .whisper('typing',this.user)
            },
            getAllUsers(){
                axios.get('getAllUsers').then(response=>{
                    this.users = response.data
                })
            },            
            async getParameters(){
                const session_id = this.chatSessionId

                this.params.recipient_id = session_id.split('-')[0];
                this.params.timestamp = session_id.split('-')[1];
                this.params.session_id = session_id
            },
            testMessage(){
                if (!!this.newMessage){
                    // this.allMessage.push({
                    //     user:this.user,
                    //     message:this.newMessage,
                    // });
                    axios.post('/client-start-chat',{
                        message:!!this.newMessage ? this.newMessage : "Is someone available to chat here?",
                    }).then(response=>{
                        this.newMessage = '';      
                    })
                }
            },
            async createSession(needAuth = true, fromId = null, text_uuid = null){
                if(needAuth){
                    Swal.fire({
                        title: 'Are you sure you want to start a conversation?',
                        text: "",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Confirm',
                        reverseButtons: true
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.chatStarted = true
                            this.updateTimer();
                            axios.post('/start-session',
                                {
                                    message_type:this.selectedMessageType
                                }
                                // message:!!this.newMessage ? this.newMessage : "Is someone available to chat here?",
                            ).then( async response=>{
                                this.session_details = response.data
                                this.saveMessage('newMessage')
                                // this.defaultMessage = ''
                                // this.session_id = response.data.id 
                            })
                        }
                    })
                }
                else{
                    axios.post('/start-session',{
                        fromText: true,
                        fromId: fromId,
                        text_uuid:text_uuid,
                        message_type:this.selectedMessageType
                    }).then(response=>{
                        if(!!response.data.error){
                            Swal.fire(response.data.error,'','error')
                            this.invalidUrl = true
                            window.location.href = 'redirection'
                            return false
                        }
                        this.chatStarted = true
                        this.updateTimer();
                        this.session_details = response.data
                        this.getMessages();
                        // this.session_id = response.data.id 
                    })
                }
               
            },
            async checkIfFromText(){
                this.flag_text = JSON.parse(this.flag_text)
                if(this.flag_text.fromText == true){
                    this.createSession(false,this.flag_text.fromBy,this.flag_text.text_uuid);
                }   
            },
            checkIfHasString(message){
                if(message.includes('data:')){
                    return false
                }
                return true
            },
            openModal(imageSrc) {
                this.selectedImage = imageSrc;
                this.showModal = true;
            },
            closeModal() {
                this.showModal = false;
            },
            chooseMessageType(){
                axios.get('getAllMessageTypes').then(response=>{
                    this.messageTypes = response.data
                })
            },
            submitSelectedMessageType(message_type){
                Swal.fire({
                        title: `You selected ${message_type.description}`,
                        text: "Do you want to continue?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        reverseButtons: true
                        }).then(async (result) =>  {
                        if (result.isConfirmed) {
                            this.selectedMessageType = message_type.id
                            this.$bvModal.hide('modal-1');
                            await this.checkIfFromText ()
                            await this.getMessages();
                        }
                        else if(result.dismiss == 'cancel'){

                        }
                      
                    })
            }
        },
        watch:{
            async chatSessionId(){
                await this.getParameters();
                await this.getMessages();
            }
        }
    }
</script>

<style scoped>
.chat-inbox {
    width: 300px; /* Adjust the width as needed */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.card{
    height: 100%;
}

.chat-header {
    background-color: #9C4E48;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

.transparent-card{
  padding: 20px; /* Add padding to the card for spacing */
  background: transparent;
  border:none;
  height:70vh;
}

.chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.chat-item {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.chat-item:hover {
    background-color: #f0f0f0; /* Adjust the hover background color */
}

.avatar {
    width: 60px; /* Adjust the avatar size as needed */
    height: 60px;
    background-color: #9C4E48; /* You can add an avatar image here */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
}

.active-convo {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
}

.selected-users-row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.selected-user {
    flex: 0 0 calc(25% - 20px); /* Adjust the width for 4 avatars per row */
    margin: 10px;
    text-align: center;
    position: relative; /* Added relative positioning */
    transition: transform 0.3s; /* Added a transition for a smooth effect */
}

.active-avatar .avatar {
    width: 80px; /* Adjust the size of the active avatar as needed */
    height: 80px;
}

.avatar {
    width: 60px; /* Adjust the avatar size as needed */
    height: 60px;
    background-color: #9C4E48; /* You can add an avatar image here */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
    line-height: 1; /* Added to vertically center the close button */
}


.send-icon {
    position: absolute;
    top: 50%;
    right: 25px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    color: #555; /* Icon color */
    cursor: pointer;
}

.message-box{
    width: auto;
    display: inline-block;
    border-radius:20px;
    -webkit-box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    -moz-box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    box-shadow: 7px 7px 0px 0px rgb(246, 247, 196);
    border: solid thin;
}
.message-box-other{
    width: auto;
    display: inline-block;
    border-radius:20px;
    -webkit-box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    -moz-box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    box-shadow: 7px 7px 0px 0px rgb(123, 211, 234);
    border: solid thin;
}

.resolved-box{
    width: auto;
    font-weight: bold;
    border-radius:20px;
    -webkit-box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
    -moz-box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
    box-shadow: 0px 7px 7px 7px rgb(161, 238, 189);
}

.claimed-box{
    width: auto;
    font-weight: bold;
    border-radius:20px;
    -webkit-box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
    -moz-box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
    box-shadow: 0px 7px 7px 7px rgb(246, 214, 214);
}

.header-button-box{
    width: auto;
    border-radius:20px;
    -webkit-box-shadow: 7px 7px 0px 0px #96CEB4;
    -moz-box-shadow: 7px 7px 0px 0px #96CEB4;
    box-shadow: 7px 7px 0px 0px #96CEB4;
    border: solid thin;
}

.dark-message{
    background-color: #E6E8EC;
}
.custom-active-tab{
    border-radius:20px;
    font-weight: bold;
    background-color: white;
    -webkit-box-shadow: 7px 7px 0px 0px #96CEB4;
    -moz-box-shadow: 7px 7px 0px 0px #96CEB4;
    box-shadow: 7px 7px 0px 0px #96CEB4;
    border: solid thin;
    color: #000;
}
.custom-button {
  padding: 20px;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 187, 255, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: #000000;
  transition: background 0.3s, border 0.3s;
  border-radius: 20px;
}

.custom-button:hover {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.b-modal .modal-content {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}
</style>