<template>
  <div class="row" style="height: 88vh">
    <div class="col-3 pl-0 pr-0" style="height: 100%">
      <div class="chat-messages inbox-box" style="height: 100%">
        <div class="search-header">
          <!-- Search icon using Font Awesome -->
          <div class="search-icon">
            <i class="fas fa-search"></i>
          </div>
          <!-- Search input field -->
          <input
            type="text"
            class="search-input"
            placeholder="Search..."
            v-model="search_params"
          />
        </div>
        <b-tabs
          v-if="hasPermission('caregiver tab')"
          pills
          card
          align="center"
          active-nav-item-class="custom-active-tab bg-light text-dark"
        >
          <b-tab
            title="Caregiver"
            active
            @click="
              room_id = null;
              setTab('caregiver');
            "
            class="text-dark"
          >
            <template #title>
              <!-- Title with badge -->
              Caregiver
              <b-badge v-if="notification_counts.caregiver > 0" variant="danger">{{
                notification_counts.caregiver
              }}</b-badge>
            </template>
            <ul
              v-for="person in users"
              class="list-unstyled mb-0"
              v-if="!!person.last_session"
            >
              <li
                @click="setRecipient(person)"
                class="p-2 inbox-box-msg my-3"
                v-if="
                  person.id != user.id &&
                  person.user_role_id == 2 &&
                  (person.last_session.claimed_by == user.id ||
                    person.last_session.claimed_by == null) &&
                  person.last_session.resolved_by == null
                "
                :style="!!person.hasNewMessage ? person.hasNewMessage : null"
              >
                <a href="#!" class="d-flex justify-content-between">
                  <div class="d-flex flex-row">
                    <img
                      :src="`https://ui-avatars.com/api/?name=${person.name}&background=random`"
                      alt="avatar"
                      class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                      width="60"
                    />
                    <div class="pt-1 pl-2 name-status">
                      <p class="mb-0">
                        <b>{{ person.name }}</b>
                        <span class="color-box-a"
                          >ID: <i>{{ person.session_id }}</i></span
                        >
                      </p>

                      <p class="small text-muted p-0 m-0">
                        {{
                          !!person.last_session
                            ? formatDateTime(person.last_session.created_at)
                            : "No Conversation at the moment"
                        }}
                      </p>
                      <p
                        class="color-box-a p-2 m-0"
                        :style="{ backgroundColor: person.message_type[0].hex_code }"
                      >
                        {{
                          !!person.last_session ? person.message_type[0].description : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="pt-1 name-status">
                    <p
                      v-if="person.last_message && person.last_message.message"
                      class="small text-muted mb-1"
                    >
                      {{ calculateDuration(person.last_message.created_at) }}
                    </p>
                    <span
                      :class="`badge ${!!person.badgeStatus ? person.badgeStatus : null}`"
                      >{{ !!person.statusText ? person.statusText : null }}</span
                    >
                  </div>
                </a>
              </li>
            </ul>
          </b-tab>
          <b-tab
            v-if="hasPermission('carecircle tab')"
            title="Coordinator"
            @click="setTab('Caregiver')"
          >
            <template #title>
              <!-- Title with badge -->
              Care Circle
              <b-badge v-if="notification_counts.coordinator > 0" variant="danger">{{
                notification_counts.coordinator
              }}</b-badge>
            </template>
            <ul v-for="person in users" class="list-unstyled mb-0">
              <li
                @click="
                  setRecipient(person);
                  recipient_id = person.id;
                "
                class="p-2 inbox-box-msg my-3"
                v-if="person.id != user.id && person.user_role_id == 1"
                :style="!!person.hasNewMessage ? person.hasNewMessage : null"
              >
                <a href="#!" class="d-flex justify-content-between">
                  <div class="d-flex flex-row">
                    <img
                      :src="`https://ui-avatars.com/api/?name=${person.name}&background=random`"
                      alt="avatar"
                      class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                      width="60"
                    />
                    <div class="pt-1 pl-2 name-status">
                      <p class="mb-0">
                        <b>{{ person.name }}</b> <span></span>{{ person.session_id }}
                      </p>

                      <p class="small text-muted">
                        {{
                          !!person.last_session
                            ? formatDateTime(person.last_session.created_at)
                            : "No Conversation at the moment"
                        }}
                      </p>
                      <p
                        class="color-box-a"
                        :style="{ backgroundColor: person.message_type[0].hex_code }"
                      >
                        {{
                          !!person.last_session
                            ? formatDateTime(person.message_type[0].description)
                            : "No Conversation at the moment"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="pt-1 name-status">
                    <p
                      v-if="person.last_message && person.last_message.message"
                      class="small text-muted mb-1"
                    >
                      {{ calculateDuration(person.last_message.created_at) }}
                    </p>
                    <!-- <span :class="`badge ${!!person.badgeStatus ? person.badgeStatus : null}`">{{ !!person.statusText ? person.statusText : null  }}</span> -->
                  </div>
                </a>
              </li>
            </ul>
          </b-tab>
          <b-tab
            v-if="hasPermission('follow-up care tab')"
            title="Room"
            @click="
              getAllRooms();
              setTab('room');
            "
          >
            <template #title>
              <!-- Title with badge -->
              Follow-Up Care
              <b-badge v-if="notification_counts.room > 0" variant="danger">{{
                notification_counts.room
              }}</b-badge>
            </template>
            <ul v-for="room in rooms" class="list-unstyled mb-0">
              <li
                @click="
                  setRoomId(room.id);
                  room_id = room.id;
                "
                class="p-2 inbox-box-msg my-3"
              >
                <a href="#!" class="d-flex justify-content-between">
                  <div class="d-flex flex-row">
                    <img
                      :src="`https://ui-avatars.com/api/?name=Room1&background=random`"
                      alt="avatar"
                      class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                      width="60"
                    />
                    <div class="pt-1 pl-2 name-status">
                      <p class="fw-bold mb-0">
                        <b>{{ formattedNames(room.all_users) }}</b>
                        <span class="badge badge-pill badge-primary">room</span>
                      </p>

                      <!-- <p class="small text-muted" v-if="person.last_message && person.last_message.message">
                                        {{ person.last_message.message }}
                                    </p>
                                    <p class="small text-muted" v-else>
                                        No message available for this person
                                    </p> -->
                    </div>
                  </div>
                  <!-- <div class="pt-1">
                                    <p v-if="person.last_message && person.last_message.message" class="small text-muted mb-1" > {{calculateDuration(person.last_message.created_at)}} </p>
                                    <span :class="`badge ${!!person.badgeStatus ? person.badgeStatus : null}`">{{ !!person.statusText ? person.statusText : null  }}</span>
                                </div> -->
                </a>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- <div class="col-7 pl-0 pr-0"> 
            <h3 class="chat-item p-4 m-4">Active Conversations</h3>
            <div class="">
                <div class="selected-users-row">
                    <div v-for="(selectedUser, index) in selectedUsers" :key="index" class="selected-user">
                        <div class="avatar">
                            <b  @click="recipient_id = selectedUser.id; openNewWindow()">{{ selectedUser.name }}</b> 
                            <span @click="removeSelectedUser(index)" class="remove-icon">&times;</span>
                        </div>
                        <div class="name"></div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="col m-0 p-0">
      <ChatWindowComponent
        v-bind:style="{ display: !!chatSessionId ? 'block' : 'none' }"
        :user="user"
        :chatSessionId="chatSessionId"
        :recipient="recipient"
        :room_id="room_id"
        :selected_tab="selected_tab"
        :userPermissions="userPermissions"
        ref="child"
        @update-notification-count="updateNotificationCount"
        @update-badge-status="updateBadgeStatus"
        @update-inbox="updateInbox"
        :sessionId="sessionId"
      ></ChatWindowComponent>
    </div>
    <div v-if="!!recipient_id && showProfile" class="col-2 py-4" style="height: 83vh">
      <div class="inbox-box" style="height: 83vh">
        <b-card
          no-body
          style="max-width: 20rem; border-radius: 20px"
          :img-src="`https://ui-avatars.com/api/?name=${
            !!recipient ? recipient.name : ''
          }&background=random`"
          img-alt="Image"
          img-top
        >
        <button @click="showProfile = false" class="close-button">×</button>
          <template #header>
            <h4 class="mb-0">{{ !!recipient ? recipient.name : "" }}</h4>
          </template>

          <b-card-body style="border-radius: 20px">
            <b-card-title>{{ !!recipient ? recipient.contact_number : "" }}</b-card-title>
            <b-card-sub-title class="mb-2"
              ><i class="fas fa-envelope"></i>:
              {{ !!recipient ? recipient.email : "" }}</b-card-sub-title
            >
            <b-card-text>
              <i class="fas fa-home"></i>: {{ !!recipient ? recipient.address : "" }}
            </b-card-text>
          </b-card-body>

          <b-list-group flush style="border-radius: 20px">
            <b-list-group-item
              ><i class="fas fa-birthday-cake"></i>: [Birthday Here]</b-list-group-item
            >
            <b-list-group-item>Other Details</b-list-group-item>
            <b-list-group-item>Other Details</b-list-group-item>
          </b-list-group>

          <!-- <b-card-body>
                    <a href="#" class="card-link">Card link</a>
                    <a href="#" class="card-link">Another link</a>
                    </b-card-body>

                    <b-card-footer>This is a footer</b-card-footer>

                    <b-card-img src="https://placekitten.com/480/210" alt="Image" bottom></b-card-img> -->
        </b-card>
      </div>
      <!-- <div class="chat-header">Profile</div> -->
    </div>
    <div v-if="!showProfile" class="col-auto py-4 px-0 ma-0" style="height: 83vh">
        <b-button @click="showProfile = true"><i class="fas fa-user"></i></b-button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
import ChatWindowComponent from "./ChatWindowComponent.vue";
import moment from "moment";
export default {
  props: ["user", "userPermissions"],
  data() {
    return {
      allMessage: [],
      newMessage: "",
      users: [],
      typingTimer: "false",
      typingUser: "",
      recipient_id: null,
      selectedUsers: [],
      windowFeatures:
        "width=500,height=300,top=100,left=100,scrollbars=yes,location=yes,resizable=yes",
      chatSessionId: null,
      recipient: null,
      durationSeconds: 0,
      durationMinutes: 0,
      daysAgo: 0,
      interval: null,
      rooms: [],
      roomMsg: [],
      room_id: null,
      selected_tab: "caregiver",
      search_params: null,
      notification_counts: {
        caregiver: 0,
        coordinator: 0,
        room: 0,
      },
      all_notifications: [],
      sessionId: null,
      showProfile: true,
    };
  },
  components: {
    ChatWindowComponent,
  },
  created() {
    this.getAllUsers();
    // window.Echo.join('client-chats')
    // .listen('ClientSendMessageEvent',(event)=>{
    //         this.getAllUsers(event);
    // })
    window.Echo.join("claim-chats").listen("ClaimMessageEvent", (event) => {
      //    if(event.session_id == this.session_id){
      this.getAllUsers();
      //    }
    });
    window.Echo.join("notif-chats").listen("NotifMessageEvent", (event) => {
      console.log(event);
      // const userIdToFetch = event.user.id;
      // const foundIndex = this.users.findIndex(user => user.id === userIdToFetch);
      // if (foundIndex !== -1) {
      // const foundUser = this.users[foundIndex];
      // this.users.splice(foundIndex, 1);
      // this.users.unshift(foundUser);
      // foundUser.last_session.claimed_by = this.user.id
      // foundUser.hasNewMessage = "background-color: yellow;"
      // foundUser.statusText = "new"
      // foundUser.badgeStatus = 'badge-danger'
      this.getAllUsers();
      // }
    });

    window.Echo.join("notif-count").listen("AilaNotificationEvent", (event) => {
      if (
        event.notification.length > 0 &&
        event.notification[0].user_id == this.user.id
      ) {
        this.all_notifications.push(event.notification[0]);
        this.setNotificationsCount();
      }
    });
  },
  methods: {
    getMessages() {
      let params = {
        params: {
          selected_user: this.recipient_id,
        },
      };
      axios.get("messages", params).then((response) => {
        this.allMessage = response.data;
      });
    },
    saveMessage() {
      if (!!this.newMessage) {
        this.allMessage.push({
          user: this.user,
          message: this.newMessage,
        });
        axios
          .post("messages", {
            message: this.newMessage,
          })
          .then((response) => {
            this.newMessage = "";
          });
      }
    },
    getTypingEvent() {
      window.Echo.join("chats").whisper("typing", this.user);
    },
    getAllUsers(event = null) {
      let params = {
        params: {
          search_params: this.search_params,
        },
      };
      axios.get("getAllUsers", params).then((response) => {
        this.users = response.data;
        this.users.forEach((e) => {
          if (!!e) {
            if (
              !!e["claimed_by"] &&
              !!e["resolved_by"] &&
              e["claimed_by"] == this.user.id
            ) {
            } else if (!!e["claimed_by"] && e["resolved_by"] == null) {
              e.statusText = "unresolved";
              e.badgeStatus = "badge-info";
            } else if (e["claimed_by"] == null) {
              e.hasNewMessage = "background-color: yellow;";
              e.statusText = "unassigned";
              e.badgeStatus = "badge-danger";
            }
          }
        });
        if (!!event) {
          const userIdToFetch = event.message.user_id;
          const foundIndex = this.users.findIndex((user) => user.id === userIdToFetch);
          if (foundIndex !== -1) {
            const foundUser = this.users[foundIndex];
            this.users.splice(foundIndex, 1);
            this.users.unshift(foundUser);
            foundUser.hasNewMessage = "background-color: #eee;";
            foundUser.statusText = "unassigned";
            foundUser.badgeStatus = "badge-danger";
            console.log(foundUser);
          }
        }
      });
    },
    async setRecipient(user) {
      console.log(user);
      this.recipient_id = user.id;
      this.recipient = user;
      user.hasNewMessage = null;
      this.chatSessionId = this.recipient_id + "-" + Date.now();
      this.sessionId = user.cs_id;

      // this.selectedUsers.push(user);
    },
    setRoomId(id) {
      this.room_id = id;
      // user.hasNewMessage = null;
      this.chatSessionId = this.recipient_id + "-" + Date.now();
      // this.selectedUsers.push(user);
    },
    setTab(tabName) {
      this.chatSessionId = null;
      this.selected_tab = tabName;
      // user.hasNewMessage = null;
      // this.chatSessionId =  this.recipient_id +'-'+Date.now();
      // this.selectedUsers.push(user);
    },

    removeSelectedUser(index) {
      this.selectedUsers.splice(index, 1);

      if (this.selectedUsers.length === 0) {
        this.recipient_id = null;
      } else {
        this.recipient_id = this.selectedUsers[0].id;
      }

      this.getMessages();
    },
    openNewWindow() {
      var windowFeatures =
        "width=600,height=800,top=100,left=100,scrollbars=yes,location=yes,resizable=yes";
      const chatSessionId = this.recipient_id + "-" + Date.now();

      Swal.fire({
        title: "Send SMS",
        text: "Do you want to send an SMS to this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(
            `http://127.0.0.1:8001/chat-window?session_id=${chatSessionId}`,
            "myWindow",
            windowFeatures
          );
        }
      });
    },
    generateAvatar(name) {
      return this.$createAvatar(name);
    },
    formatDateTime(date) {
      return this.$formatDate(date);
    },
    ditotest() {
      alert("adasd");
    },
    calculateDuration(dt) {
      const now = moment();
      const datetime = moment(dt);

      // Duration in seconds
      let durationSeconds = 0;
      durationSeconds = now.diff(datetime, "seconds");

      // Duration in minutes
      let durationMinutes = 0;
      durationMinutes = now.diff(datetime, "minutes");

      let durationHours = 0;
      durationHours = now.diff(datetime, "hours");

      // Difference in days
      let daysAgo = 0;
      daysAgo = now.diff(datetime, "days");

      let durationTime;

      console.log(durationSeconds, durationMinutes, daysAgo);
      if (durationSeconds < 60) {
        durationTime = "just now";
      }
      if (durationSeconds > 60 && durationMinutes > 0) {
        durationTime = `${durationMinutes} minutes ago`;
      }
      if (durationMinutes > 60 && durationHours < 24) {
        if (durationHours == 1) {
          durationTime = `${durationHours} hour ago`;
        } else {
          durationTime = `${durationHours} hours ago`;
        }
      }
      if (daysAgo) {
        if (daysAgo == 1) {
          durationTime = `${daysAgo} day ago`;
        } else {
          durationTime = `${daysAgo} days ago`;
        }
      }

      return durationTime;
    },
    getAllRooms() {
      axios.get("getAllRooms").then((response) => {
        this.rooms = response.data;
      });
    },
    formattedNames(names) {
      console.log(names);
      // Use Array.join() to concatenate the array elements with commas
      return names.join(", ");
    },
    hasPermission(permissionName) {
      return this.userPermissions.some(
        (permission) => permission.name === permissionName
      );
    },
    messageDecryptor(encryptedMessage) {
      let params = {
        params: {
          message: encryptedMessage,
        },
      };
      let msg = "";
      axios.get("decryptMessage", params).then((response) => {
        console.log(response.data);
        msg = response.data;
        return msg;
      });
    },
    showNotification() {
      axios.get("showNotification").then((response) => {
        if (!!response.data.length > 0) {
          this.all_notifications = response.data;
          this.setNotificationsCount();
        }
      });
    },
    setNotificationsCount() {
      this.notification_counts.caregiver = _.filter(this.all_notifications, [
        "type",
        1,
      ]).length;
      this.notification_counts.coordinator = _.filter(this.all_notifications, [
        "type",
        2,
      ]).length;
      this.notification_counts.room = _.filter(this.all_notifications, [
        "type",
        3,
      ]).length;
    },
    updateNotificationCount(sessionId) {
      this.all_notifications = _.filter(
        this.all_notifications,
        (obj) => obj.session_id !== sessionId
      );
      this.seenNotification(sessionId);
      this.setNotificationsCount();
    },
    seenNotification(sessionId) {
      axios
        .post("seenNotification", {
          session_id: sessionId,
        })
        .then((response) => {});
    },
    updateBadgeStatus(value, color, userIdToFetch) {
      const foundIndex = this.users.findIndex(
        (user) => user.id === Number(userIdToFetch)
      );
      if (foundIndex !== -1) {
        const foundUser = this.users[foundIndex];
        this.users.splice(foundIndex, 1);
        this.users.unshift(foundUser);
        foundUser.hasNewMessage = null;
        foundUser.statusText = value;
        foundUser.badgeStatus = color;
      }
    },
    updateInbox(isNull) {
      this.getAllUsers();
      if (!!isNull) {
        this.chatSessionId = null;
      }
    },
  },
  mounted() {
    this.interval = setInterval(this.calculateDuration, 60000);
    this.showNotification();
    // axios.post('/sendMail').then(response=>{
    //     alert('Email Sent');
    // })
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {},
  watch: {
    search_params() {
      this.getAllUsers();
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .name-status {
    display: none; /* Hide the name-message class on small screens */
  }
}
body {
  overflow: hidden;
}
.chat-inbox {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.chat-header {
  background-color: #9c4e48;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

.chat-messages {
  padding: 10px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

.chat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-item {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.chat-item:hover {
  background-color: #f0f0f0; /* Adjust the hover background color */
}

.avatar {
  width: 60px; /* Adjust the avatar size as needed */
  height: 60px;
  background-color: #b4b4b4 !important; /* You can add an avatar image here */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
}

.selected-users-row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.selected-user {
  flex: 0 0 calc(25% - 20px); /* Adjust the width for 4 avatars per row */
  margin: 10px;
  text-align: center;
  position: relative; /* Added relative positioning */
  transition: transform 0.3s; /* Added a transition for a smooth effect */
}

.active-avatar .avatar {
  width: 80px; /* Adjust the size of the active avatar as needed */
  height: 80px;
}

.avatar {
  background-color: #b4b4b4 !important; /* You can add an avatar image here */
  border-radius: 50%;
  width: 60px; /* Adjust the avatar size as needed */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
  line-height: 1; /* Added to vertically center the close button */
}

.user-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  max-height: 88vh;
}

/* Style for the user avatar */
.user-avatar {
  background-color: #b4b4b4 !important; /* You can add an avatar image here */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

/* Style for user details */
.user-details {
  /* margin-top: 20px; */
  height: 80vh;
}

/* Style for user detail items */
.user-detail-item {
  margin-bottom: 10px;
  align-items: start;
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #333; */
  padding: 10px;
  color: #000000;
}

/* Style the search icon */
.search-icon {
  font-size: 24px;
  margin-right: 10px;
}

/* Style the search input */
.search-input {
  flex-grow: 1;
  border: none;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  padding: 10px;
}

/* Style the search input when focused */
.search-input:focus {
  outline: none;
}

.newMessageHighlight {
  background-color: #eee;
}

.new-design-message {
  -webkit-box-shadow: 14px 18px 17px 0px rgba(255, 0, 255, 1);
  -moz-box-shadow: 14px 18px 17px 0px rgba(255, 0, 255, 1);
  box-shadow: 14px 18px 17px 0px rgba(255, 0, 255, 1);
}

.inbox-box {
  width: auto;
  border-radius: 20px;
  -webkit-box-shadow: 7px 7px 0px 0px rgb(163, 163, 163);
  -moz-box-shadow: 7px 7px 0px 0px rgb(163, 163, 163);
  box-shadow: 7px 7px 0px 0px rgb(163, 163, 163);
}
.inbox-box-msg {
  width: auto;
  border-radius: 20px;
  -webkit-box-shadow: 0px 2px 2px 2px rgb(163, 163, 163);
  -moz-box-shadow: 0px 2px 2px 2px rgb(163, 163, 163);
  box-shadow: 0px 2px 2px 2px rgb(163, 163, 163);
  border-bottom: solid black thin;
}
.badge-position {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
.color-box-a {
  display: inline-block;
  border-radius: 5px;
  padding: 6px;
  border: 1px solid #ccc;
  font-size: 10px;
  font-style: italic bold;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white; /* or another color depending on your image and theme */
  z-index: 1;
}

.close-button:hover {
  color: red; /* Change color on hover */
}
</style>
