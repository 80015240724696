<template>
    <div>
      <label for="dropdown">Select User:</label>
      <select class="form-control" id="dropdown" v-model="selectedItem">
        <option v-for="item in items" :key="item.id" :value="item.id" v-if="item.id != user.id && item.role.id != 2">{{ item.name }}</option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      user: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        selectedItem: null
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>